import { useTranslation } from 'react-i18next';
import { Col, Row } from 'sam-ui-kit';

import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormInputField } from 'components/Form/FormInputField';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';

import styles from './SealAttributesItem.module.scss';

export const SealAttributesItem = ({
    attributeIndex,
    onDelete,
    fieldPath = ''
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.seal}>
            <div className={styles.header}>
                <span className={styles.title}>{t('applications:seal')}</span>

                <span
                    className={styles.delete}
                    onClick={() => onDelete(attributeIndex)}
                >
                    {t('actions.delete')}
                </span>
            </div>
            <Row gutter={[12, 12]}>
                <Col span={5} sm={12}>
                    <FormSegmentControlField
                        name={`${fieldPath}.mainSeal`}
                        segmentsConfig={[
                            {
                                label: t('applications:main'),
                                value: true
                            },
                            {
                                label: t('applications:additional'),
                                value: false
                            }
                        ]}
                        noHintSpace={true}
                    />
                </Col>
                <Col span={2} sm={12}>
                    <FormInputField
                        name={`${fieldPath}.sealNumber`}
                        placeholder={t('applications:seal_number')}
                        noHintSpace={true}
                    />
                </Col>
                <Col span={2} sm={12}>
                    <FormAutocomplete
                        clearable
                        name={`${fieldPath}.sealType`}
                        placeholder={t('applications:type')}
                        catalogName="ATTRIBUTE_SEALS_TYPE"
                        useFullModel={true}
                    />
                </Col>
                <Col span={3} sm={12}>
                    <FormAutocomplete
                        clearable
                        name={`${fieldPath}.sealColor`}
                        placeholder={t('applications:color')}
                        catalogName="ATTRIBUTES_COLOR"
                        useFullModel={true}
                    />
                </Col>
                <Col span={5} sm={12}>
                    <FormAutocomplete
                        clearable
                        name={`${fieldPath}.location`}
                        placeholder={t('applications:location')}
                        catalogName="LOCATION"
                        useFullModel={true}
                    />
                </Col>
                <Col span={7} sm={12}>
                    <FormInputField
                        name={`${fieldPath}.remark`}
                        placeholder={t('applications:remark')}
                        noHintSpace={true}
                    />
                </Col>
            </Row>
        </div>
    );
};
