import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LanguageSelect.module.scss';

const Option = (props) => {
    const { t } = useTranslation();
    const {
        option: { value, title },
        onClick
    } = props;

    const handleClick = (clickedValue) => () => {
        onClick(clickedValue);
    };

    return (
        <li
            className={styles.option}
            value={value}
            onClick={handleClick(value)}
            tabIndex={0}
        >
            {t(title)}
        </li>
    );
};

export const LanguageSelect = ({
    options,
    placeholder,
    selected,
    onChange,
    onClose
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const rootRef = useRef(null);
    const { t } = useTranslation();

    const selectedOption = options.find((value) => value.value === selected);
    useEffect(() => {
        const handleClick = (event) => {
            const { target } = event;
            if (target instanceof Node && !rootRef.current?.contains(target)) {
                isOpen && onClose?.();
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [isOpen, onClose]);

    const handleOptionClick = (value) => {
        setIsOpen(false);
        onChange?.(value);
    };
    const handlePlaceHolderClick = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div
            className={clsx(styles.select, isOpen && styles['select_active'])}
            ref={rootRef}
        >
            <div
                className={styles.placeholder}
                onClick={handlePlaceHolderClick}
            >
                {t(selectedOption?.title || placeholder)}
            </div>
            <div
                className={clsx(styles.icon, isOpen && styles['icon_active'])}
                onClick={handlePlaceHolderClick}
            >
                <ChevronDown size={20} color="#FFFFFF" />
            </div>
            {isOpen && (
                <ul className={styles.options}>
                    {options.map((option) => (
                        <Option
                            key={option.value}
                            option={option}
                            onClick={handleOptionClick}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};
