import { differenceInHours, format, isValid, subHours } from 'date-fns';

import { DEFAULT_DATETIME_MASK } from '../settings';

export const formatDate = (date, mask = DEFAULT_DATETIME_MASK) => {
    if (!date || !isValid(new Date(date))) return '';

    return format(new Date(date), mask);
};

export const getISODateTimeWithTimezoneOffset = (date) => {
    if (!date || !isValid(new Date(date))) return '';

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, -1)
        .split('.')[0];
};

export const formatSubHours = (date, hours = 1) => {
    if (!date || !isValid(new Date(date))) return '';

    return getISODateTimeWithTimezoneOffset(subHours(new Date(date), hours));
};
export const getDifferenceInHours = (startDate, endDate) => {
    if (
        !startDate ||
        !isValid(new Date(startDate)) ||
        !endDate ||
        !isValid(new Date(endDate))
    )
        return '';
    return differenceInHours(new Date(startDate), new Date(endDate));
};
