import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { noop } from 'lodash';
import { Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';

import { TextLink } from 'components/TextLink/TextLink';

import {
    ACCEPTED,
    AWAITING_ACCEPTANCE,
    REJECTED
} from 'modules/Applications/consts';
import { deleteCarriage } from 'modules/Applications/services/services';

import styles from './CarriageItem.module.scss';

export const CarriageItem = ({
    number,
    data,
    refetch,
    status,
    onRemoveProtection
}) => {
    const { t } = useTranslation();

    const deleteMutation = useMutation({
        mutationFn: deleteCarriage,
        onSuccess: refetch
    });

    const handleDelete = () => {
        showModal('confirm-delete').then(() => {
            deleteMutation.mutate({
                appId: data.appId,
                id: data.id
            });
        }, noop);
    };

    return (
        <div className={styles.carriage}>
            <div className={styles.header}>
                <div className={styles.field}>
                    <span className={styles.number}>{`${number}.`}</span>
                    <TextLink
                        label={data.wagonCode}
                        url={`carriage/${data.id}`}
                    />
                </div>
                {status === AWAITING_ACCEPTANCE && (
                    <Trash2
                        size={20}
                        color="#C85858"
                        onClick={handleDelete}
                        cursor="pointer"
                    />
                )}
            </div>
            <div className="divider" />
            <div className={styles.body}>
                <div className={styles.item}>
                    <span>{`${t('applications:initial_accept')}:`}</span>
                    <span
                        className={clsx(
                            styles.value,
                            data.initialStatus === REJECTED
                                ? styles.rejected
                                : styles.accepted
                        )}
                    >
                        {t(`applications:status_cargo.${data.initialStatus}`)}
                    </span>
                </div>
                <div className={styles.item}>
                    <span>{`${t('applications:comment')}:`}</span>
                    <span
                        className={clsx(
                            styles.value,
                            data.defect && styles.regular
                        )}
                    >
                        {t(
                            data.defect
                                ? data.defect.nameRu || '-'
                                : 'actions.no'
                        )}
                    </span>
                </div>
                <div className={styles.item}>
                    <span>{`${t('common:note')}:`}</span>
                    <span
                        className={clsx(
                            styles.value,
                            data.remark && styles.regular
                        )}
                    >
                        {t(data.remark || 'actions.no')}
                    </span>
                </div>
            </div>
            {data.detached && (
                <div className={styles.detached}>
                    {t('situations:removed_under_protection')}
                </div>
            )}
            {!data.detached &&
                data.initialStatus === REJECTED &&
                status === ACCEPTED && (
                    <div className={styles.action}>
                        <Button
                            label={t('situations:remove_protection')}
                            block={true}
                            onClick={() => onRemoveProtection(data)}
                        />
                    </div>
                )}
        </div>
    );
};
