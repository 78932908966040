import { noop } from 'lodash';
import { Filter } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';

import { Header } from 'components/Header/Header';

import { DefectsFiltersModal } from 'modules/Defects/components/DefectsFiltersModal/DefectsFiltersModal';

import styles from './DefectsHeader.module.scss';

export const DefectsHeader = ({ refetch }) => {
    const { t } = useTranslation();

    const handleFiltersClick = () => {
        showModal(DefectsFiltersModal).then(refetch, noop);
    };

    return (
        <div className={styles.header}>
            <Header
                title={t('defects:defects')}
                actions={
                    <div>
                        <Button
                            label={t('actions.filter')}
                            variant="default"
                            size="md"
                            icon={<Filter size={16} />}
                            onClick={handleFiltersClick}
                        />
                    </div>
                }
            />
        </div>
    );
};
