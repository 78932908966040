import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button } from 'sam-ui-kit';

import { Divider } from 'components/Divider/Divider';
import { TextLink } from 'components/TextLink/TextLink';

import styles from './Card.module.scss';

export const Card = ({
    title,
    status,
    children,
    hideActions = false,
    headerDirection = 'horizontal',
    path,
    onClick,
    size = 'sm'
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={clsx(styles.card, {
                [styles.sm]: size === 'sm',
                [styles.md]: size === 'md'
            })}
        >
            <div
                className={clsx(styles.header, {
                    [styles.vertical]: headerDirection === 'vertical'
                })}
            >
                {path ? (
                    <TextLink label={title} url={path} />
                ) : (
                    <span className={styles.title}>{title}</span>
                )}
                {status}
            </div>
            <Divider
                classNames={
                    size === 'md' ? 'm-top-16 m-bot-16' : 'm-top-12 m-bot-12'
                }
            />
            <div className={styles.content}>{children}</div>
            {!hideActions && (
                <>
                    <Divider />
                    <Button
                        label={t('actions.watch')}
                        block={true}
                        onClick={onClick}
                        variant="primary"
                    />
                </>
            )}
        </div>
    );
};
