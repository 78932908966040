import { isSuccessResponse } from 'utils/http';

export const DataRequestPageListNormalizer = (response) => {
    const result = {};

    result.totalPages = response?.data?.data?.totalPages || 0;
    result.totalElements = response?.data?.data?.totalElements || 0;

    if (isSuccessResponse(response)) {
        result.content = response.data.data.content;
        result.number = response.data.data.number;
    }

    return result;
};

export const DataRequestAutocompleteListNormalizer = (response) => {
    const result = {};

    result.totalPages = response?.data?.data?.totalPages || 0;
    result.totalElements = response?.data?.data?.totalElements || 0;

    if (isSuccessResponse(response)) {
        result.content = response.data.data.content;
        result.number = response.data.data.number;

        for (let i = 0; i < result.content.length; i++) {
            if (result.content[i].codeR) {
                result.content[i].code_r = result.content[i].codeR;
            }
        }
    }

    return result;
};
export const DataRequestDetailsNormalizer = (response) => {
    if (
        response.data?.status === 'SUCCESS' &&
        response.data.data.content.length > 0
    ) {
        return response.data.data.content;
    }
    return [];
};

export const EmmRequestNormalizer = (response) => {
    if (response.data?.status === 'SUCCESS') {
        return response.data.data;
    }
    return [];
};

export const EmmRequestNormalizer2 = (response) => {
    if (response.data?.status === 'SUCCESS') {
        return response.data;
    }
    return [];
};

export const DefaultNormalizer = (response) => {
    if (response.data?.status === 'SUCCESS') {
        return response.data.data;
    }
    return {};
};
