import { isNil, omitBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'sam-ui-kit';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';
import { createModal } from 'core/modal';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormDateIntervalPickerField } from 'components/Form/FormDateIntervalPickerField';
import { FormInputField } from 'components/Form/FormInputField';
import { FormMultiSelectField } from 'components/Form/FormMultiSelectField';
import { RequiredField } from 'components/Form/rules';

export const SituationsFiltersModal = createModal(
    (props) => {
        const { t } = useTranslation();
        const userStationCode = useSelector(
            (state) => state.auth.user?.profileDataMap?.STATION_CODE
        );
        const [defaultValues, setCustomFilters] = useQueryParam(
            'customFilters',
            withDefault(CustomFilterParam, {
                arrival_station_code_eq: userStationCode
            })
        );
        const methods = useForm({
            defaultValues: defaultValues ?? {}
        });

        const handleSubmit = (data) => {
            setCustomFilters(
                omitBy(data, (value) => {
                    return isNil(value) || value.length === 0;
                })
            );
            props.onOk();
        };

        const handleCancel = () => {
            methods.trigger(['arrival_station_code_eq']).then((valid) => {
                if (valid) {
                    const arrival_station_code_eq = methods.getValues(
                        'arrival_station_code_eq'
                    );
                    setCustomFilters({ arrival_station_code_eq });
                    props.onOk();
                }
            });
        };

        return (
            <Modal
                title={t('filter')}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'md'}
                {...props}
            >
                <Form methods={methods} onSubmit={handleSubmit}>
                    <Modal.ModalBody size={props.isMobile && 'full'}>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormInputField
                                        label={t('situations:train_index')}
                                        name="index_poezd_like"
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormInputField
                                        label={t('situations:train_number')}
                                        name="nom_poezd_like"
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="arrival_station_code_eq"
                                        label={t('station')}
                                        catalogName="STATIONS"
                                        rules={RequiredField}
                                    />
                                </Col>
                                <div className="divider m-bot-10" />

                                <Col span={12}>
                                    <FormDateIntervalPickerField
                                        label={t('situations:arrival_period')}
                                        startDateName="arrival_date_gte"
                                        endDateName="arrival_date_lte"
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormDateIntervalPickerField
                                        label={t('situations:departure_Period')}
                                        startDateName="departure_date_gte"
                                        endDateName="departure_date_lte"
                                    />
                                </Col>
                                <div className="divider m-bot-10" />

                                <Col span={12}>
                                    <FormMultiSelectField
                                        label="Статус"
                                        name="status_in"
                                        options={[
                                            {
                                                value: 'ACCEPTED',
                                                label: t('statuses.accepted')
                                            },
                                            {
                                                value: 'ARRIVING',
                                                label: t('statuses.arriving')
                                            },
                                            {
                                                value: 'NOT_ACCEPTED',
                                                label: t(
                                                    'statuses.not_accepted'
                                                )
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="accept_guard_username"
                                        label={t('applications:security')}
                                        catalogName="GUARDS"
                                        placeholder={t(
                                            'applications:choose_security'
                                        )}
                                        menuPlacement="top"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Button
                                label={t('actions.reset_filters')}
                                block={props.isMobile}
                                onClick={handleCancel}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.accept')}
                                type="submit"
                                block={props.isMobile}
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        chainOnClose: true,
        displayName: 'SituationsFiltersModal'
    }
);
