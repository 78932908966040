import { isBoolean, isNil } from 'lodash';
import { useMemo } from 'react';

export const useIsSituationAcceptable = (data) => {
    return useMemo(() => {
        if (data.status !== 'NOT_ACCEPTED') {
            return false;
        }
        let result = true;

        const acceptableWagons = data.wagons.filter(
            ({ acceptable }) => acceptable
        );

        for (let i = 0; i < acceptableWagons.length; i++) {
            if (isNil(acceptableWagons[i].wagonAcceptanceInSituationDefect)) {
                return false;
            }
            if (!isBoolean(acceptableWagons[i].wagonAcceptanceInSituation)) {
                return false;
            }
        }
        return result;
    }, [data]);
};
