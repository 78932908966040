import { Check, Pencil, Trash2, X } from 'lucide-react';
import { Space } from 'sam-ui-kit';

export const TableRowEditControls = ({ edit, onCancel, onDelete, onEdit }) => {
    const buttons = edit
        ? [
              <button className="reset-button" type="submit" key="reset">
                  <Check
                      size={16}
                      color="#1A1A1A"
                      cursor="pointer"
                      style={{ display: 'block' }}
                  />
              </button>,
              <X
                  size={16}
                  color="#EC5454"
                  cursor="pointer"
                  onClick={onCancel}
                  style={{ display: 'block' }}
                  key="cancel"
              />
          ]
        : [
              <Pencil
                  size={16}
                  color="#4079E4"
                  cursor="pointer"
                  onClick={onEdit}
                  key="edit"
              />,
              onDelete && (
                  <Trash2
                      size={16}
                      color="#C85858"
                      cursor="pointer"
                      onClick={onDelete}
                      key="delete"
                  />
              )
          ];
    return <Space gap={12}>{buttons}</Space>;
};
