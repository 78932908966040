import { useTranslation } from 'react-i18next';

import { StatusText } from 'components/StatusText/StatusText';

import {
    ACCEPTED,
    AWAITING_ACCEPTANCE,
    DETACHED,
    NOT_ACCEPTED
} from 'modules/Applications/consts';

export const ApplicationStatus = ({ status }) => {
    const { t } = useTranslation();
    switch (status) {
        case DETACHED:
            return (
                <StatusText
                    label={t('applications:status_cargo.DETACHED')}
                    variant="red"
                />
            );
        case AWAITING_ACCEPTANCE:
            return (
                <StatusText
                    label={t('applications:status_cargo.AWAITING_ACCEPTANCE')}
                    variant="orange"
                />
            );
        case ACCEPTED:
            return (
                <StatusText
                    label={t('applications:status_cargo.ACCEPTED')}
                    variant="green"
                />
            );
        case NOT_ACCEPTED:
            return (
                <StatusText
                    label={t('applications:status_cargo.NOT_ACCEPTED')}
                    variant="grey"
                />
            );
        default:
            return <StatusText label={''} variant="" />;
    }
};
