import clsx from 'clsx';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useScreenSize } from 'hooks/useScreenSize';

import { Card } from 'components/Card/Card';
import { TextButton } from 'components/TextButton/TextButton';

import {
    DEFAULT_SEAL,
    DEFAULT_TWIST,
    LEFT_DIRECTION,
    RIGHT_DIRECTION,
    SEAL_TYPE,
    TWIST_TYPE
} from 'modules/Applications/consts';

import { SealAttributesItem } from '../SealAttributesItem/SealAttributesItem';
import { SealAttributesItemView } from '../SealAttributesItemView/SealAttributesItemView';
import { TwistAttributesItem } from '../TwistAttributesItem/TwistAttributesItem';
import { TwistAttributesItemView } from '../TwistAttributesItemView/TwistAttributesItemView';
import styles from './CarriageAttributes.module.scss';

export const CarriageAttributes = ({
    fields = [],
    onAppend,
    onDelete,
    isDisabled
}) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    const handleAddSeal = (side) => {
        onAppend({
            ...DEFAULT_SEAL,
            sealSide: side
        });
    };

    const handleAddTwist = (side) => {
        onAppend({
            ...DEFAULT_TWIST,
            twistSide: side
        });
    };

    const handleDelete = (index) => {
        onDelete(index);
    };

    return (
        <div className={styles.attributes}>
            <Card
                title={t('applications:left_side')}
                hideActions={true}
                size="md"
                headerDirection={isMobile ? 'vertical' : 'horizontal'}
            >
                {fields.map((attribute, i) => {
                    let Attribute = null;
                    if (
                        attribute.sealSide === RIGHT_DIRECTION ||
                        attribute.twistSide === RIGHT_DIRECTION
                    ) {
                        return null;
                    }
                    if (attribute.type === SEAL_TYPE) {
                        Attribute = isDisabled ? (
                            <SealAttributesItemView data={attribute} />
                        ) : (
                            <SealAttributesItem
                                onDelete={handleDelete}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                            />
                        );
                    }
                    if (attribute.type === TWIST_TYPE) {
                        Attribute = isDisabled ? (
                            <TwistAttributesItemView data={attribute} />
                        ) : (
                            <TwistAttributesItem
                                onDelete={handleDelete}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                            />
                        );
                    }

                    return (
                        <Fragment key={attribute.carriageAttributesFieldId}>
                            {Attribute}
                            {i !== fields.length - 1 && (
                                <div
                                    className={clsx('divider', styles.divider)}
                                />
                            )}
                        </Fragment>
                    );
                })}
                {!isDisabled && (
                    <div
                        className={clsx(
                            styles.actions,
                            isMobile && styles.fullWidth
                        )}
                    >
                        <TextButton
                            label={`+ ${t('applications:add_seal')}`}
                            onClick={() => handleAddSeal(LEFT_DIRECTION)}
                            noUnderline={true}
                        />
                        <TextButton
                            label={`+ ${t('applications:add_twist')}`}
                            onClick={() => handleAddTwist(LEFT_DIRECTION)}
                            noUnderline={true}
                        />
                    </div>
                )}
            </Card>
            <Card
                title={t('applications:right_side')}
                hideActions={true}
                size="md"
                headerDirection={isMobile ? 'vertical' : 'horizontal'}
            >
                {fields.map((attribute, i) => {
                    let Attribute = null;
                    if (
                        attribute.sealSide === LEFT_DIRECTION ||
                        attribute.twistSide === LEFT_DIRECTION
                    ) {
                        return null;
                    }
                    if (attribute.type === SEAL_TYPE) {
                        Attribute = isDisabled ? (
                            <SealAttributesItemView data={attribute} />
                        ) : (
                            <SealAttributesItem
                                onDelete={handleDelete}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                            />
                        );
                    }
                    if (attribute.type === TWIST_TYPE) {
                        Attribute = isDisabled ? (
                            <TwistAttributesItemView data={attribute} />
                        ) : (
                            <TwistAttributesItem
                                onDelete={handleDelete}
                                attributeIndex={i}
                                fieldPath={`attributes.${i}`}
                            />
                        );
                    }

                    return (
                        <Fragment key={attribute.carriageAttributesFieldId}>
                            {Attribute}
                            {i !== fields.length - 1 && (
                                <div
                                    className={clsx('divider', styles.divider)}
                                />
                            )}
                        </Fragment>
                    );
                })}
                {!isDisabled && (
                    <div
                        className={clsx(
                            styles.actions,
                            isMobile && styles.fullWidth
                        )}
                    >
                        <TextButton
                            label={`+ ${t('applications:add_seal')}`}
                            onClick={() => handleAddSeal(RIGHT_DIRECTION)}
                            noUnderline={true}
                        />
                        <TextButton
                            label={`+ ${t('applications:add_twist')}`}
                            onClick={() => handleAddTwist(RIGHT_DIRECTION)}
                            noUnderline={true}
                        />
                    </div>
                )}
            </Card>
        </div>
    );
};
