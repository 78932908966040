import { useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneDeep, snakeCase } from 'lodash';
import { useEffect } from 'react';

import { eventBus } from 'core/eventBus';

import { escapeSpecialChars } from 'utils/textUtils';

import { DataRequestPageListNormalizer } from '../normalizers/DataRequest';

/**
 *
 * @param queryKey Уникальный ключ-строка запроса.
 * @param api Функция запрос для получения данных
 * @param pagination Параметры пагинации.
 * @param sort Параметры сортировки.
 * @param columnFilters Фильтрация с колонок таблицы.
 * @param extendedFilters Расширенная фильтрация с модального окна.
 * @param customFilters Дополнительные параметры фультрации. Напрмер DateInteval.
 * @param onSuccess Callback вызов при успешном получении данных.
 * @param [updateEventName] Подписка на сообщение об успешном обновлении данных хука.
 * @param [isEnabled] Ручное включение/отключение отправки запроса.
 */
export const useDataRequest = (
    queryKey,
    api,
    { pagination, sort, columnFilters, extendedFilters, customFilters },
    updateEventName,
    isEnabled = true
) => {
    const queryClient = useQueryClient();

    useEffect(() => {
        if (updateEventName) {
            return eventBus.subscribe(updateEventName, (item) => {
                queryClient.setQueryData(
                    [
                        queryKey,
                        pagination,
                        sort,
                        extendedFilters,
                        columnFilters,
                        customFilters
                    ],
                    (oldState) => {
                        const nextState = cloneDeep(oldState);
                        const index = nextState.content.findIndex(
                            (e) => e.id === item.id
                        );
                        if (index !== -1) {
                            nextState.content[index] = {
                                ...nextState.content[index],
                                ...item
                            };
                        } else {
                            nextState.content.unshift(item);
                        }
                        return nextState;
                    }
                );
            });
        }
    }, [
        queryKey,
        queryClient,
        pagination,
        sort,
        extendedFilters,
        columnFilters,
        customFilters,
        updateEventName
    ]);

    return useQuery({
        queryKey: [
            queryKey,
            pagination,
            sort,
            extendedFilters,
            columnFilters,
            customFilters
        ],
        queryFn: () => {
            let filterParams = customFilters || {};

            if (columnFilters) {
                filterParams = {
                    ...filterParams,
                    ...toRequestColumnFilters(columnFilters)
                };
            }
            if (extendedFilters) {
                filterParams = {
                    ...filterParams,
                    ...toRequestExtendedFilters(extendedFilters)
                };
            }

            return api(
                {
                    pageNumber: pagination?.pageIndex,
                    pageSize: pagination?.pageSize,
                    orders: sort ? toRequestSort(sort) : undefined,
                    ...filterParams
                },
                DataRequestPageListNormalizer
            );
        },
        enabled: isEnabled
    });
};

const toRequestSort = (sort) =>
    sort
        .map(
            (item) =>
                `${snakeCase(item.id)} ${item.desc === true ? 'desc' : 'asc'}`
        )
        .join(',');

const toRequestColumnFilters = (columnFilters) =>
    columnFilters.reduce((result, item) => {
        const [filterValue, filterType] = item.value;
        switch (filterType) {
            case 'equal':
                result[`${snakeCase(item.id)}_eq`] = filterValue;
                break;
            case 'interval':
                if (filterValue.start) {
                    result[`${snakeCase(item.id)}_gte`] = filterValue.start;
                }
                if (filterValue.end) {
                    result[`${snakeCase(item.id)}_lte`] = filterValue.end;
                }
                break;
            case 'relatedField':
                result[item.id] = filterValue;
                break;
            case 'nlike':
                result[`${snakeCase(item.id)}_nlike`] =
                    escapeSpecialChars(filterValue);
                break;
            default:
                result[`${snakeCase(item.id)}_like`] =
                    escapeSpecialChars(filterValue);
        }

        return result;
    }, {});

const toRequestExtendedFilters = (columnFilters) =>
    columnFilters.reduce((result, item) => {
        //TODO проверить работу с датами
        result[`${snakeCase(item.id)}`] = item.value.value;
        return result;
    }, {});
