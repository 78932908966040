import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateIntervalPicker, FieldControl } from 'sam-ui-kit';

import { FormStatusContext } from 'components/Form/Form';

export const FormDateIntervalPickerField = ({
    rules,
    disabled,
    noHintSpace,
    ...otherProps
}) => {
    const methods = useFormContext();
    const formStatus = useContext(FormStatusContext);
    const { t } = useTranslation();

    const { field: startDateField, fieldState: fieldStartDateState } =
        useController({
            name: otherProps.startDateName,
            control: methods.control,
            rules
        });

    const { field: endDateField, fieldState: fieldEndDateState } =
        useController({
            name: otherProps.endDateName,
            control: methods.control,
            rules
        });

    const handleStartDateChange = (stringDate) => {
        startDateField.onChange({
            target: {
                value: stringDate,
                name: otherProps.startDateName
            }
        });
    };
    const handleEndDateChange = (stringDate) => {
        endDateField.onChange({
            target: {
                value: stringDate,
                name: otherProps.endDateName
            }
        });
    };

    const handleClean = () => {
        handleStartDateChange(null);
        handleEndDateChange(null);
    };

    return (
        <FieldControl
            label={otherProps.label}
            hint={
                (fieldStartDateState.error &&
                    t(fieldStartDateState.error?.message)) ||
                (fieldEndDateState.error &&
                    t(fieldEndDateState.error?.message)) ||
                otherProps.hint
            }
            required={rules?.required.value}
            error={fieldStartDateState.invalid || fieldEndDateState.invalid}
            noHintSpace={noHintSpace}
        >
            <DateIntervalPicker
                {...otherProps}
                disabled={disabled || formStatus.disabled}
                startDate={startDateField.value}
                endDate={endDateField.value}
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
                onClear={otherProps.isCleanable && handleClean}
                error={fieldStartDateState.invalid || fieldEndDateState.invalid}
                errorMessage={
                    (fieldStartDateState.error &&
                        t(fieldStartDateState.error?.message)) ||
                    (fieldEndDateState.error &&
                        t(fieldEndDateState.error?.message))
                }
            />
        </FieldControl>
    );
};
