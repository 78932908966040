import { isNil } from 'lodash';
import { useMemo } from 'react';

export const useWagonFieldRule = (fieldName, index) => {
    return useMemo(() => {
        return {
            validate: (value, formData) => {
                const {
                    wagonAcceptanceInSituation,
                    wagonAcceptanceInSituationDefect
                } = formData.wagons[index];

                if (
                    (isNil(wagonAcceptanceInSituation) &&
                        isNil(wagonAcceptanceInSituationDefect)) ||
                    (!isNil(wagonAcceptanceInSituation) &&
                        !isNil(wagonAcceptanceInSituationDefect))
                ) {
                    return true;
                }

                if (
                    fieldName === 'wagonAcceptanceInSituation' &&
                    !isNil(wagonAcceptanceInSituation)
                ) {
                    return true;
                }

                if (
                    fieldName === 'wagonAcceptanceInSituationDefect' &&
                    !isNil(wagonAcceptanceInSituationDefect)
                ) {
                    return true;
                }

                return false;
            },
            required: { value: false, message: 'form.validation.requiredField' }
        };
    }, [fieldName, index]);
};
