import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'sam-ui-kit';
import { createModal } from 'src/core/modal';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormDateIntervalPickerField } from 'components/Form/FormDateIntervalPickerField';
import { FormSelectField } from 'components/Form/FormSelectField';
import { RequiredField } from 'components/Form/rules';

import { getApplicationStatusOptions } from 'modules/Applications/utils';

export const ApplicationsFiltersModal = createModal(
    (props) => {
        const { t } = useTranslation();

        const userStationCode = useSelector(
            (state) => state.auth.user?.profileDataMap?.STATION_CODE
        );
        const [defaultValues, setCustomFilters] = useQueryParam(
            'customFilters',
            withDefault(CustomFilterParam, {
                appearance_station_code_eq: userStationCode
            })
        );
        const methods = useForm({
            defaultValues: defaultValues ?? {}
        });

        const handleSubmit = (data) => {
            setCustomFilters(data);
            props.onOk();
        };

        const handleReset = () => {
            methods.reset({
                appearance_station_code_eq: null
            });
        };

        return (
            <Modal
                title={t('filter')}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'md'}
                {...props}
            >
                <Form methods={methods} onSubmit={handleSubmit}>
                    <Modal.ModalBody size={props.isMobile && 'full'}>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormDateIntervalPickerField
                                        startDateName="dt_gte"
                                        endDateName="dt_lte"
                                        label={t(
                                            'applications:application_date'
                                        )}
                                        isCleanable={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="wagon_code_eq"
                                        label={t('applications:carriage')}
                                        catalogName="WAGON_NUMBER"
                                        placeholder={t(
                                            'applications:carriage_number'
                                        )}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="cargo_code_eq"
                                        label={t('applications:cargo_category')}
                                        catalogName="CARGO_CODES"
                                        placeholder={t(
                                            'applications:choose_category'
                                        )}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="appearance_station_code_eq"
                                        label={t('station')}
                                        catalogName="STATIONS"
                                        placeholder={t(
                                            'applications:choose_station'
                                        )}
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormSelectField
                                        label={t('status')}
                                        name="status_eq"
                                        placeholder={t(
                                            'applications:choose_status'
                                        )}
                                        options={getApplicationStatusOptions(t)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="guard_eq"
                                        label={t('applications:security')}
                                        catalogName="GUARDS"
                                        placeholder={t(
                                            'applications:choose_security'
                                        )}
                                        menuPlacement="top"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Button
                                label={t('actions.reset_filters')}
                                block={props.isMobile}
                                onClick={handleReset}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.accept')}
                                type="submit"
                                block={props.isMobile}
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        chainOnClose: true,
        displayName: 'ApplicationsFiltersModal'
    }
);
