import { useMutation } from '@tanstack/react-query';
import { Upload } from 'lucide-react';
import { useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'sam-ui-kit';

import { loadWagonInfoFromFile } from 'modules/Situations/services';
import { notificateNaturalSheetErrorResponse } from 'modules/Situations/utils';

export const NaturalSheetUpload = ({ name, rules, onUploadSuccess }) => {
    const ref = useRef();
    const methods = useFormContext();
    const { t } = useTranslation();

    const { field } = useController({
        name,
        control: methods.control,
        rules
    });

    const fileMutation = useMutation({
        mutationFn: loadWagonInfoFromFile,
        onSuccess: (data) => {
            field.onChange(data);
            onUploadSuccess(data);
        },
        onError: (response) => notificateNaturalSheetErrorResponse(response, t)
    });

    const handleClick = () => {
        ref.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            fileMutation.mutate(selectedFiles[0]);
        }
    };

    return (
        <div className="center">
            <input ref={ref} type="file" hidden onChange={handleFileChange} />
            <Button
                variant="default"
                label={t('situations:upload_natural_sheet')}
                icon={<Upload size={16} color="#100B1D" />}
                onClick={handleClick}
                loading={fileMutation.isPending}
            />
        </div>
    );
};
