import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { Space } from 'sam-ui-kit';

import styles from './MainMenu.module.scss';

export const MainMenu = ({ menuItems }) => {
    const location = useLocation();
    const module = getModuleName(location);
    const matcher = useMatch('/:module?/*');
    const { t } = useTranslation();
    return (
        <div className={styles.menu}>
            <ul>
                <Space gap={30} displayDivider={true}>
                    {menuItems.map((item) => (
                        <span
                            className={clsx(module === item.module)}
                            key={item.url}
                        >
                            <NavLink
                                to={item.url}
                                className={() => {
                                    if (
                                        matcher.params.module === undefined &&
                                        item.module === 'situations'
                                    ) {
                                        return styles.active;
                                    }
                                    return matcher.params.module === item.module
                                        ? styles.active
                                        : null;
                                }}
                            >
                                {item.icon}
                                {t(item.module)}
                            </NavLink>
                        </span>
                    ))}
                </Space>
            </ul>
        </div>
    );
};

export const getModuleName = (location) => {
    const paths = location.pathname
        .split('/')
        .filter((path) => path.length > 0);

    return paths.length > 0 ? paths[0] : 'route';
};
