import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout';

// import { FallbackPage } from 'components/FallbackPage/FallbackPage';
// import { ErrorIcon } from 'components/icons/ErrorIcon';

export const ErrorPage = () => (
    <DefaultLayout>
        fallback
        {/*<FallbackPage*/}
        {/*    icon={<ErrorIcon />}*/}
        {/*    title="error_title"*/}
        {/*    subTitle="error_sub_title"*/}
        {/*/>*/}
    </DefaultLayout>
);
