import { useMutation, useQuery } from '@tanstack/react-query';
import { pick } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'sam-ui-kit';

import { createModal } from 'core/modal';

import { notificateErrorResponse } from 'utils/serverErrors';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormDatePickerField } from 'components/Form/FormDatePickerField';
import { FormInputField } from 'components/Form/FormInputField';
import { RequiredField } from 'components/Form/rules';

import { useRefetchDashboard } from 'modules/Situations/hooks/useRefetchDashboard';
import {
    getSituationDetails,
    updateSituation
} from 'modules/Situations/services';

export const ArrivalModal = createModal(
    (props) => {
        const { id } = props;
        const { t } = useTranslation();
        const refetch = useRefetchDashboard();

        const formData = useQuery({
            queryKey: ['getSituationDetails', id],
            queryFn: () => getSituationDetails(id),
            enabled: !!id
        });

        const methods = useForm({
            defaultValues: {}
        });

        const mutation = useMutation({
            mutationFn: (data) => updateSituation(id, data),
            onSuccess: () => {
                refetch();
                props.onOk();
            },
            onError: (response) => notificateErrorResponse(response, t)
        });

        useEffect(() => {
            if (formData.data) {
                methods.reset(
                    pick(formData.data, [
                        'arrivalDate',
                        'arrivalPark',
                        'railwayLine'
                    ])
                );
            }
        }, [formData.data, methods]);

        return (
            <Modal
                title={'Добавить прибытие'}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'md'}
                {...props}
            >
                <Form
                    methods={methods}
                    onSubmit={mutation.mutate}
                    disabled={mutation.isPending || formData.isLoading}
                >
                    <Modal.ModalBody size={props.isMobile && 'full'}>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormDatePickerField
                                        label={t(
                                            'situations:date_time_arrival'
                                        )}
                                        name="arrivalDate"
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormAutocomplete
                                        name="arrivalPark"
                                        label={t('situations:park')}
                                        catalogName="station_parks"
                                        useFullModel={true}
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormInputField
                                        label={t('situations:path')}
                                        name="railwayLine"
                                        rules={RequiredField}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Modal.CancelButton
                                label={t('actions.cancel')}
                                block={props.isMobile}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.save')}
                                type="submit"
                                block={props.isMobile}
                                disabled={
                                    mutation.isPending || formData.isLoading
                                }
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        displayName: 'ArrivalModal'
    }
);
