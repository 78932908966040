import { useTranslation } from 'react-i18next';
import { Col, Row } from 'sam-ui-kit';

import { InfoField } from 'components/InfoField/InfoField';

import styles from './SealAttributesItemView.module.scss';

export const SealAttributesItemView = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.seal}>
            <div className={styles.header}>
                <span className={styles.title}>{t('applications:seal')}</span>
            </div>
            <Row gutter={[12, 12]}>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:attribute')}
                        value={t(
                            `applications:${data.mainSeal ? 'main' : 'additional'}`
                        )}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:seal_number')}
                        value={data.sealNumber}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:type')}
                        value={data.sealType?.nameRu}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:color')}
                        value={data.sealColor?.nameRu}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:location')}
                        value={data.location?.nameRu}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:remark')}
                        value={data.remark}
                    />
                </Col>
            </Row>
        </div>
    );
};
