import { FieldControl } from 'sam-ui-kit';

import { AutocompleteField } from 'components/AutocompleteField/AutocompleteField';

export const SituationAcceptGuard = ({
    label,
    value,
    onChange,
    errorMessage,
    disabled
}) => {
    return (
        <FieldControl
            label={label}
            hint={errorMessage}
            required={true}
            error={!!errorMessage}
            noHintSpace={false}
        >
            <AutocompleteField
                name="acceptGuard"
                catalogName="GUARDS"
                useFullModel={true}
                disabled={disabled}
                value={value}
                onChange={(_, fullModel) => {
                    onChange(fullModel);
                }}
                error={!!errorMessage}
            />
        </FieldControl>
    );
};
