import { useTranslation } from 'react-i18next';

import { StatusText } from 'components/StatusText/StatusText';

export const DefectStatus = ({ status, noBackground = false }) => {
    const { t } = useTranslation();

    switch (status) {
        case 'AOF':
            return (
                <StatusText
                    label={t('defects:status.AOF')}
                    variant="orange"
                    noBackground={noBackground}
                />
            );
        case 'TELEPHONOGRAM':
            return (
                <StatusText
                    label={t('defects:status.TELEPHONOGRAM')}
                    variant="orange"
                    noBackground={noBackground}
                />
            );
        case 'REJECTED':
            return (
                <StatusText
                    label={t('defects:status.REJECTED')}
                    variant="red"
                    noBackground={noBackground}
                />
            );
        case 'ACTIVE':
            return (
                <StatusText
                    label={t('defects:status.ACTIVE')}
                    variant="green"
                    noBackground={noBackground}
                />
            );
        default:
            return (
                <StatusText label={''} variant="" noBackground={noBackground} />
            );
    }
};
