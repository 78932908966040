import { isValidElement } from 'react';

import styles from './CardValue.module.scss';

export const CardValue = ({ label, value }) => {
    return (
        <div className={styles.row}>
            <span>{`${label}:`}</span>
            {isValidElement(value) ? value : <span>{value}</span>}
        </div>
    );
};
