import {
    DATA_REQUEST_DATA_URL,
    DATA_REQUEST_EMM_URL,
    DATA_REQUEST_URL
} from 'settings';

import { getFIOFull, getStationOptionLabel } from './textUtils';

const defaultConfig = (path) => {
    return {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/${path}`,
        optionDisplayFields: ['codeR', `nameRu`]
    };
};

export const CatalogsConfig = {
    LOCO_TYPE: defaultConfig('loco_type_ui'),
    WORK_PLACE: defaultConfig('work_place_ui'),
    USER_POSITION: defaultConfig('user_position_ui'),
    DEPARTMENT: defaultConfig('department_ui'),
    DEPOTS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_DATA_URL}/depots`,
        optionDisplayFields: ['code', `name`]
    },
    DEPOT_PLACE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/depo_place_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    PROFESSIONS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/professions_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    TRAFFIC_TYPE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/traffic_type_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    LOCO_PROPULSION: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/loco_propulsion_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    TRAIN_CATEGORY_UI: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/train_category_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    TRACTION_TYPE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/traction_types_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    ACCESS_GROUP: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/access_group_ui`,
        optionDisplayFields: [`nameRu`]
    },
    TRAIN_TYPE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/train_type_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    MOVEMENT_TYPE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/ismr_movement_type_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    COMPANY: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/company_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    STATIONS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_DATA_URL}/stations`,
        optionDisplayFields: ['code', `name`]
    },
    GAS_STATIONS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_DATA_URL}/gas_stations`,
        optionDisplayFields: ['code', `name`]
    },
    STATION_PLACE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/station_place_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    DRIVERS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_EMM_URL}/drivers_ui`,
        optionDisplayFields: ['code', 'fullName']
    },
    DRIVERS_BY_ID: {
        valueField: 'id',
        findName: 'id',
        url: `${DATA_REQUEST_EMM_URL}/drivers_ui`,
        optionDisplayFields: ['code', 'fullName']
    },
    DRIVERS_BY_PERSONNEL_NUMBER: {
        valueField: 'personnelNumber',
        findName: 'personnelNumber',
        url: `${DATA_REQUEST_EMM_URL}/drivers_ui`,
        optionDisplayFields: ['personnelNumber', 'fullName']
    },
    SECTIONS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_DATA_URL}/sections`,
        optionDisplayFields: ['code', 'name']
    },
    NOTE_TYPE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/note_type_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    WORK_KINDS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/work_kinds_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    REST_HOUSE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/rest_house_ui`,
        optionDisplayFields: ['code', `nameRu`]
    },
    U4_DATA: {
        url: `${DATA_REQUEST_EMM_URL}/u4_track_spans`,
        valueField: 'index',
        renderOptionLabel: getStationOptionLabel
    },
    MEDICAL_WORKERS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/medical_workers_ui`,
        renderOptionLabel: getFIOFull
    },
    INSTRUCTORS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/instructors_ui`,
        renderOptionLabel: getFIOFull
    },
    WAGON_NUMBER_AVAILABLE: {
        valueField: 'wagonNumber',
        findName: 'wagonNumber',
        url: `${DATA_REQUEST_DATA_URL}/cargo_wagon_numbers_available`,
        optionDisplayFields: ['wagonNumber']
    },
    WAGON_NUMBER: {
        valueField: 'wagonNumber',
        findName: 'wagonNumber',
        url: `${DATA_REQUEST_DATA_URL}/cargo_wagon_numbers`,
        optionDisplayFields: ['wagonNumber']
    },
    CARGO_CODES: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_DATA_URL}/cargo_codes`,
        optionDisplayFields: ['nameRu']
    },
    ATTRIBUTE_SEALS_TYPE: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/attribute_seals_type_ui`,
        optionDisplayFields: ['nameRu']
    },
    ATTRIBUTE_SEALS_TYPE_BY_NAME: {
        valueField: 'nameRu',
        findName: 'nameRu',
        url: `${DATA_REQUEST_URL}/attribute_seals_type_ui`,
        optionDisplayFields: ['nameRu']
    },
    ATTRIBUTES_COLOR: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/attributes_color_ui`,
        optionDisplayFields: ['nameRu']
    },
    LOCATION: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/attributes_location_ui`,
        optionDisplayFields: ['nameRu']
    },
    GUARDS: {
        valueField: 'username',
        findName: 'username',
        url: `${DATA_REQUEST_DATA_URL}/guards`,
        renderOptionLabel: getFIOFull
    },
    WAGON_DEFECTS: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/wagon_defects_ui`,
        optionDisplayFields: ['nameRu']
    },
    station_parks: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/station_parks_ui`,
        optionDisplayFields: ['nameRu']
    },
    park_types: {
        valueField: 'code',
        findName: 'code',
        url: `${DATA_REQUEST_URL}/park_types_ui`,
        optionDisplayFields: ['nameRu']
    }
};
