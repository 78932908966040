import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateIntervalPicker, FieldControl, Input } from 'sam-ui-kit';
import { useQueryParam } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';

import { AutocompleteField } from 'components/AutocompleteField/AutocompleteField';
import { Header } from 'components/Header/Header';

import styles from './HistoryHeader.module.scss';

export const HistoryHeader = ({ isLoading }) => {
    const { t } = useTranslation();
    const [customFilters, setCustomFilters] = useQueryParam(
        'customFilters',
        CustomFilterParam
    );

    const [container, setContainer] = useState(
        customFilters?.container_code_like || ''
    );

    const handleInputChange = (event) => {
        setContainer(event?.target.value || '');
        debounce(() => {
            setCustomFilters((prevState) => ({
                ...prevState,
                container_code_like: event?.target.value || undefined
            }));
        }, 1000)();
    };

    useEffect(() => {
        setContainer(customFilters?.container_code_like || '');
    }, [customFilters?.container_code_like]);

    return (
        <div className={styles.header}>
            <Header
                title={t('history:app_wagon_history')}
                actions={
                    <div className={styles.actions}>
                        <FieldControl noHintSpace={true}>
                            <AutocompleteField
                                value={customFilters?.wagon_code_eq}
                                clearable
                                name="wagon_code_eq"
                                catalogName="WAGON_NUMBER"
                                placeholder={t('history:carriage_number')}
                                onChange={(value) =>
                                    setCustomFilters((prevState) => ({
                                        ...prevState,
                                        wagon_code_eq: value
                                    }))
                                }
                                disabled={isLoading}
                            />
                        </FieldControl>

                        <FieldControl noHintSpace={true}>
                            <Input
                                name="container_code_like"
                                placeholder={t('history:container')}
                                value={container}
                                onChange={handleInputChange}
                                disabled={isLoading}
                            />
                        </FieldControl>

                        <FieldControl noHintSpace={true}>
                            <DateIntervalPicker
                                disabled={isLoading}
                                startDate={customFilters?.dt_gte}
                                endDate={customFilters?.dt_lte}
                                onStartDateChange={(value) =>
                                    setCustomFilters((prevState) => ({
                                        ...prevState,
                                        dt_gte: value
                                    }))
                                }
                                onEndDateChange={(value) =>
                                    setCustomFilters((prevState) => ({
                                        ...prevState,
                                        dt_lte: value
                                    }))
                                }
                                onClear={() =>
                                    setCustomFilters((prevState) => ({
                                        ...prevState,
                                        dt_gte: null,
                                        dt_lte: null
                                    }))
                                }
                            />
                        </FieldControl>
                    </div>
                }
            />
        </div>
    );
};
