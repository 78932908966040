import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DISPLAY_DATE_FORMAT, SYSTEM_DATE_FORMAT } from 'settings';

export const useSamUiKitConfig = () => {
    const { t } = useTranslation();

    return useMemo(() => {
        return {
            t: {
                modal: {
                    okLabel: t('modal.okLabel'),
                    cancelLabel: t('modal.cancelLabel'),
                    closeConfirm: {
                        closeModalLabel: t(
                            'modal.closeConfirm.closeModalLabel'
                        ),
                        backToModalLabel: t(
                            'modal.closeConfirm.backToModalLabel'
                        ),
                        title: t('modal.closeConfirm.title'),
                        description: t('modal.closeConfirm.description')
                    }
                },
                calendar: {
                    timeInputLabel: t('calendar:timeInputLabel'),
                    months: [
                        t('calendar:months.january'),
                        t('calendar:months.february'),
                        t('calendar:months.march'),
                        t('calendar:months.april'),
                        t('calendar:months.may'),
                        t('calendar:months.june'),
                        t('calendar:months.july'),
                        t('calendar:months.august'),
                        t('calendar:months.september'),
                        t('calendar:months.october'),
                        t('calendar:months.november'),
                        t('calendar:months.december')
                    ]
                },
                table: {
                    emptyText: t('no_data'),
                    loadingText: `${t('loading')}...`,
                    search: t('actions.search')
                },
                collapse: {
                    openLabel: t('routes:show'),
                    closeLabel: t('routes:hide')
                },
                select: {
                    noOptionsMessage: t('no_data'),
                    loadingMessage: t('loading')
                }
            },
            settings: {
                calendar: {
                    yearSelect: {
                        startYear: 1990,
                        endYear: 2035
                    },
                    systemDateFormat: SYSTEM_DATE_FORMAT,
                    displayDateFormat: DISPLAY_DATE_FORMAT
                },
                theme: 'qorgau'
            }
        };
    }, [t]);
};
