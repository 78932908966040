import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'sam-ui-kit';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { SHOW_LIST_OF_ESCORTS, SHOW_LIST_OF_ONCOMING } from 'core/permissions';

import { useIsPermissionEnabled } from 'hooks/useIsPermissionEnabled';
import { useSize } from 'hooks/useSize';

import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';

import styles from './SituationsFilters.module.scss';

export const SituationsFilters = ({ situationPosts, isLoading }) => {
    const ref = useRef();
    const isPermissionEnabled = useIsPermissionEnabled();
    const { t } = useTranslation();
    const { showSmall } = useSize(ref);
    const queryClient = useQueryClient();
    const [activePost, setActivePost] = useQueryParam(
        'activePost',
        withDefault(StringParam, 'all')
    );

    const cargoCount =
        queryClient.getQueriesData({
            queryKey: ['cargoEscortList'],
            stale: true,
            type: 'active'
        })[0]?.[1]?.length ?? 0;

    const oncomingCount =
        queryClient.getQueriesData({
            queryKey: ['cargoOncomingList'],
            stale: true,
            type: 'active'
        })[0]?.[1]?.length ?? 0;

    return (
        <div className={styles.situationsFilters} ref={ref}>
            {isLoading ? (
                <SkeletonLoader width="100%" height={29}>
                    <rect width={55} height={29} rx={15} ry={15} />
                    <rect x={70} width={155} height={29} rx={15} ry={15} />
                    <rect x={240} width={155} height={29} rx={15} ry={15} />
                </SkeletonLoader>
            ) : (
                <Tabs
                    variant="chips"
                    tabs={getTabs(
                        t,
                        activePost,
                        situationPosts,
                        showSmall,
                        isPermissionEnabled,
                        cargoCount,
                        oncomingCount
                    )}
                    value={activePost}
                    onChange={setActivePost}
                />
            )}
        </div>
    );
};

const getTabs = (
    t,
    activePost,
    situationPosts,
    showSmall,
    isPermissionEnabled,
    cargoCount,
    oncomingCount
) => {
    const tabs = [
        {
            label: t('all'),
            value: 'all'
        }
    ];

    situationPosts.forEach((post) => {
        tabs.push({
            label: (
                <>
                    <span
                        className={clsx(styles.greyText, {
                            [styles.active]:
                                post.arrivalPark?.code === activePost
                        })}
                    >
                        {t('situations:post')}:
                    </span>{' '}
                    {post.arrivalPark?.nameRu ?? t('not_defined')} (
                    {post.situations?.length ?? 0})
                </>
            ),
            value: post.arrivalPark?.code ?? 'not-defined'
        });
    });

    if (!showSmall) {
        if (isPermissionEnabled(SHOW_LIST_OF_ESCORTS)) {
            tabs.push({
                value: 'escort',
                label: `${t('situations:escort')} (${cargoCount})`
            });
        }
        if (isPermissionEnabled(SHOW_LIST_OF_ONCOMING)) {
            tabs.push({
                value: 'oncoming',
                label: `${t('situations:oncoming')} (${oncomingCount})`
            });
        }
    }

    return tabs;
};
