import clsx from 'clsx';

import styles from './CellField.module.scss';

export const CellField = ({ value, size = 14 }) => {
    return (
        <span
            className={clsx(
                styles.cellField,
                styles[`cellField__size_${size}`]
            )}
        >
            {value}
        </span>
    );
};
