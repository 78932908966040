import { useInfiniteQuery } from '@tanstack/react-query';
import { useId } from 'react';

import api from 'core/api';
import { DataRequestAutocompleteListNormalizer } from 'core/normalizers/DataRequest';

const PAGE_SIZE = 10;

export const useAutocompleteOptionsList = ({
    catalog,
    autocomplete,
    isDisabled,
    extraFilters
}) => {
    const id = useId();

    const { isFetching, isFetchingNextPage, fetchNextPage, data } =
        useInfiniteQuery({
            queryKey: [getUrl(catalog), autocomplete, extraFilters, id],
            queryFn: ({ pageParam = 1 }) =>
                api.get(getUrl(catalog), {
                    params: getRequestParams(
                        autocomplete,
                        pageParam,
                        extraFilters
                    ),
                    normalizer: DataRequestAutocompleteListNormalizer
                }),
            getNextPageParam: (lastPage, allPages) => {
                if (
                    lastPage.content.length === PAGE_SIZE &&
                    lastPage.content.length !== lastPage.totalElements
                ) {
                    return lastPage.number + 1;
                }
            },
            enabled: !isDisabled
        });

    return {
        isFetching: isFetching || isFetchingNextPage,
        pagedList: data,
        fetchNextPage
    };
};
const getUrl = (catalog) => {
    return catalog.url;
};

const getRequestParams = (autocomplete, pageParam, extraFilters) => {
    const result = {
        size: PAGE_SIZE,
        number: pageParam
    };

    if (autocomplete) {
        result.anywhere = autocomplete;
    }

    extraFilters?.forEach((filter) => {
        result[
            filter.operation
                ? `${filter.searchName}_${filter.operation}`
                : filter.searchName
        ] = filter.value;
    });

    return result;
};
