import { useSelector } from 'react-redux';

let cache = {};

export const clearPermissionCache = () => {
    cache = {};
};
/**
 * @returns
 *      isPermissionEnabled [function(permission: string)] проверка доступности одного разрешения
 *      isPermissionEnabled.some [function(permissions: Array<string>)] проверка доступности хотя бы одного из переданных разрешений
 *      isPermissionEnabled.every [function(permissions: Array<string>)] проверка доступности всех переданных разрешений
 */
export const useIsPermissionEnabled = () => {
    const permissions = useSelector(
        (state) => state.auth.permissions,
        (a, b) => {
            if (a !== b) {
                cache = {};
            }
            return a === b;
        }
    );

    const isPermissionEnabled = (p) => {
        if (cache[p] !== undefined) {
            return cache[p];
        } else {
            const isEnabled = permissions.includes(p);
            cache[p] = isEnabled;
            return isEnabled;
        }
    };

    isPermissionEnabled.some = (p) => {
        if (Array.isArray(p)) {
            return p.some(isPermissionEnabled);
        } else {
            return isPermissionEnabled(p);
        }
    };

    isPermissionEnabled.every = (p) => {
        if (Array.isArray(p)) {
            return p.every(isPermissionEnabled);
        } else {
            return isPermissionEnabled(p);
        }
    };

    return isPermissionEnabled;
};
