import { createSlice } from '@reduxjs/toolkit';

import { clearPermissionCache } from 'hooks/useIsPermissionEnabled';

import {
    loadAuthState,
    resetLocalStorage,
    setLocalStorage
} from 'utils/localStorageUtils';

import { changePassword, login } from 'modules/Auth/actions';
import { userPermissionsNormalizer } from 'modules/Auth/normalizers/userPermissionsNormalizer';

const getAuthInitialState = () => {
    return {
        //isLoggedIn: localStorage.getItem('isLoggedIn') === 'true'
        token: '',
        refreshToken: '',
        user: null,
        permissions: [],
        isAuthLoading: false,
        isLoggedIn: false,
        fieldErrors: [],
        // isSendEmailLoading: false,
        // isResetPasswordLoading: false,
        ...loadAuthState()
    };
};

const authSlice = createSlice({
    name: 'auth',
    initialState: getAuthInitialState,
    reducers: {
        setToken(state, action) {
            state.token = action.payload;
        },
        setUser(state, action) {
            state.user = action.payload;
            state.permissions = userPermissionsNormalizer(action.payload);
        },
        setAuthData(state, { payload }) {
            const { token, refreshToken, user } = payload;

            if (token) {
                state.isLoggedIn = true;
                state.token = token;
                state.refreshToken = refreshToken;
                state.user = user;
                state.permissions = userPermissionsNormalizer(user);
                // patchMapbox(
                //     permissions.find((item) => item === 'USE_MAPBOX_PROXY')
                // );
                setLocalStorage({ ...payload, isLoggedIn: true });
            } else {
                resetLocalStorage();
            }
        },
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
            state.user = null;
            state.auth = null;
            clearPermissionCache();
        },
        reset: () => getAuthInitialState()
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isAuthLoading = true;
            })
            .addCase(login.rejected, (state, action) => {
                state.isAuthLoading = false;
                state.statusCode = action.payload?.status;
                //TODO обработать кейс когда ответ не получен
                if (action.payload?.status === 'INCORRECT_USERNAME_PASSWORD') {
                    state.fieldErrors = [
                        {
                            fieldName: 'password',
                            error: 'login.incorrectLoginOrPassword'
                        },
                        {
                            fieldName: 'username'
                        }
                    ];
                }
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isAuthLoading = false;
                if (action.payload?.token) {
                    state.token = action.payload.token;
                    state.refreshToken = action.payload.refreshToken;
                    state.user = action.payload.user;
                    const permissions = userPermissionsNormalizer(
                        action.payload.user
                    );
                    state.permissions = permissions;
                    state.isLoggedIn = true;
                    // patchMapbox(
                    //     permissions.find((item) => item === 'USE_MAPBOX_PROXY')
                    // );

                    setLocalStorage({
                        ...action.payload,
                        permissions,
                        isLoggedIn: true
                    });
                }
            })
            .addCase(changePassword.pending, (state) => {
                state.isAuthLoading = true;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.isAuthLoading = false;
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.isAuthLoading = false;
            });
    }
});

export const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
