import { useTranslation } from 'react-i18next';
import { formatDate } from 'src/utils/dateUtils';

import { CardValue } from 'components/CardValue/CardValue';

import { AWAITING_ACCEPTANCE } from 'modules/Applications/consts';

export const ReceptionCardContent = ({ data = {} }) => {
    const { t } = useTranslation();

    return (
        <>
            <CardValue
                label={t('applications:application_date')}
                value={data.createdAt ? formatDate(data.dt) : '-'}
            />
            <CardValue
                label={t('applications:acceptance_start')}
                value={data.acceptedAt ? formatDate(data.acceptedAt) : '-'}
            />
            <CardValue
                label={t('applications:acceptance_end')}
                value={data.finishedAt ? formatDate(data.finishedAt) : '-'}
            />
            <CardValue
                label={t('station')}
                value={data.acceptanceStation?.name || '-'}
            />
            <CardValue label={t('cargo')} value={data.cargoType?.name || '-'} />
            <CardValue
                label={t('applications:applicant')}
                value={data.applicant || '-'}
            />
            {data.status && data.status !== AWAITING_ACCEPTANCE && (
                <>
                    <CardValue
                        label={t('applications:security')}
                        value={data.guardFullname || '-'}
                    />
                    <CardValue
                        label={t('applications:psg')}
                        value={data.psg || '-'}
                    />
                </>
            )}
            <CardValue
                label={t('applications:amount')}
                value={data.wagonCount || 0}
            />
        </>
    );
};
