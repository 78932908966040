import axios from 'axios';
import qs from 'qs';

import { loginResponseNormalizer } from 'modules/Auth/normalizers/loginResponseNormalizer';
import { authActions } from 'modules/Auth/reducers';

import {
    AUTH_API_URL,
    INVALID_TOKEN_STATUS,
    SUCCESS_STATUS
} from '../settings';
import { store } from './store';

const api = axios.create({
    headers: {
        'Content-Type': 'application/json'
    },
    paramsSerializer: {
        serialize: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
        }
    },
    timeout: 30000
});

const apiInterceptors = (apiInstance, store) => {
    const { dispatch } = store;
    let isRefreshing = false;
    let failedQueue = [];

    apiInstance.interceptors.request.use(
        (config) => {
            const { token } = store.getState().auth;

            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const processQueue = (error, token = null) => {
        failedQueue.forEach((request) => {
            if (error) {
                request.reject(error);
            } else {
                request.resolve(token);
            }
        });

        failedQueue = [];
    };

    apiInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (responseError) => {
            const originalRequest = responseError.config;

            if (
                responseError.response?.status === 401 &&
                !originalRequest._retry
            ) {
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((token) => {
                            originalRequest.headers['Authorization'] =
                                'Bearer ' + token;

                            return axios(originalRequest);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }

                if (!isRefreshing) {
                    isRefreshing = true;
                    originalRequest._retry = true;

                    const { refreshToken } = store.getState().auth;

                    return new Promise((resolve, reject) => {
                        axios
                            .post(AUTH_API_URL + '/token/refresh', null, {
                                headers: {
                                    Authorization: 'Bearer ' + refreshToken
                                }
                            })
                            .then((data) => {
                                const response = loginResponseNormalizer(data);

                                if (response.status === SUCCESS_STATUS) {
                                    apiInstance.defaults.headers.common[
                                        'Authorization'
                                    ] = 'Bearer ' + response.token;

                                    originalRequest.headers['Authorization'] =
                                        'Bearer ' + response.token;

                                    dispatch(authActions.setAuthData(response));

                                    processQueue(null, response.token);

                                    resolve(axios(originalRequest));
                                } else if (
                                    response.status === INVALID_TOKEN_STATUS
                                ) {
                                    dispatch(authActions.setIsLoggedIn(false));
                                    dispatch(authActions.setToken(''));
                                }
                            })
                            .catch((error) => {
                                if (
                                    error.response?.data?.status ===
                                    INVALID_TOKEN_STATUS
                                ) {
                                    dispatch(authActions.setIsLoggedIn(false));
                                    dispatch(authActions.setToken(''));
                                } else {
                                    processQueue(error, null);
                                }

                                reject(error);
                            })
                            .then(() => {
                                isRefreshing = false;
                                originalRequest._retry = false;
                            });
                    });
                }
            } else if (responseError.response?.status === 500) {
                // dispatch(
                //     notificationActions.add({
                //         message: 'server_error_default_message',
                //         type: 'error'
                //     })
                // );
            }

            return Promise.reject(responseError);
        }
    );

    apiInstance.interceptors.response.use((response) => {
        if (response.config.normalizer) {
            return response.config.normalizer(response);
        }
        return response;
    });

    // apiInstance.interceptors.response.use(async (response) => {
    //     if (process.env.NODE_ENV === 'development') {
    //         await sleep();
    //     }
    //     return response;
    // });
};

export function sleep(ms = 2000) {
    console.log('Kindly remember to remove `sleep`');
    return new Promise((resolve) => setTimeout(resolve, ms));
}

apiInterceptors(api, store);

export default api;
