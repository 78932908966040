import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateUtils';

import { Card } from 'components/Card/Card';
import { CardValue } from 'components/CardValue/CardValue';

import { getStatusField } from 'modules/Situations/utils';

export const SituationCard = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Card
            title={`${t('situation')} №${data.id}`}
            status={getStatusField(data, t)}
            hideActions={true}
        >
            <CardValue label={t('situations:index')} value={data.indexPoezd} />
            <CardValue
                label={t('situations:arrival')}
                value={formatDate(data.arrivalDate)}
            />
            <CardValue
                label={t('situations:departure')}
                value={formatDate(data.departureDate)}
            />
            <CardValue label={t('station')} value={data.arrivalStation?.name} />
            <CardValue
                label={t('situations:security')}
                value={data.guardedWagonsCount}
            />
            <CardValue
                label={t('situations:post')}
                value={data.arrivalPark?.nameRu}
            />
        </Card>
    );
};
