import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'sam-ui-kit';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormInputField } from 'components/Form/FormInputField';
import { RequiredField } from 'components/Form/rules';

import { AWAITING_ACCEPTANCE } from 'modules/Applications/consts';

export const ReceptionForm = ({ data = {}, mutation, isLoading }) => {
    const methods = useForm({
        defaultValues: {
            guard: '',
            psg: ''
        }
    });

    useEffect(() => {
        if (Object.keys(data).length) {
            methods.reset({
                guard: data.guard,
                psg: data.psg,
                id: data.id
            });
        }
    }, [data, methods]);

    const { t } = useTranslation();

    const isDisabled =
        isLoading ||
        (data.status && data.status !== AWAITING_ACCEPTANCE) ||
        data.wagonCount !== data.acceptedWagonCount;

    return (
        <Form
            methods={methods}
            onSubmit={mutation.mutate}
            disabled={isDisabled}
        >
            <FormAutocomplete
                clearable
                name="guard"
                label={t('applications:security')}
                catalogName="GUARDS"
                placeholder={t('applications:choose_security')}
                rules={RequiredField}
            />
            <FormInputField label={t('applications:psg')} name="psg" />

            <Button
                variant="primary"
                type="submit"
                label={t('applications:secure_accept')}
                block={true}
                disabled={isDisabled}
            />
        </Form>
    );
};
