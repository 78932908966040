import { configureStore } from '@reduxjs/toolkit';

import { screenSizeReducer } from 'core/reducers/screenSize';

import { authReducer } from 'modules/Auth/reducers';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        screenSize: screenSizeReducer
    }
});
