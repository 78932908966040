import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import { Reception } from '../components/Reception/Reception';
import { ReceptionPageHeader } from '../components/ReceptionPageHeader/ReceptionPageHeader';
import { getCargosList } from '../services/services';
import styles from './ReceptionPage.module.scss';

export const ReceptionPage = () => {
    const { id } = useParams();

    const { data, isFetching } = useQuery({
        queryKey: ['getCargosList', id],
        queryFn: () =>
            getCargosList({
                id_eq: id
            }),
        enabled: !!id,
        initialData: []
    });

    return (
        <div className={clsx(styles.reception, 'flex-column gap-32')}>
            <ReceptionPageHeader
                applicationData={data[0]}
                applicationIsFetching={isFetching}
            />
            <Reception
                applicationData={data[0]}
                applicationIsFetching={isFetching}
            />
        </div>
    );
};
