import { concat, debounce, isNil, reduce } from 'lodash';
import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { BaseSelect } from 'sam-ui-kit';

import { useAutocompleteOptionsList } from 'hooks/useAutocompleteOptionsList';
import { useAutocompleteValue } from 'hooks/useAutocompleteValue';

import { CatalogsConfig } from 'utils/CatalogsConfig';

export const AutocompleteField = ({
    value,
    onChange,
    name,
    disabled,
    catalogName = '',
    extraFilters,
    menuPlacement,
    error,
    isSearchable = true,
    isClearable = true,
    placeholder = '',
    useFullModel,
    menuPosition,
    menuIsOpen
}) => {
    const [autocomplete, setAutocomplete] = useState('');
    const [menuOpenState, setMenuOpenState] = useState(false);

    const { isFetching, pagedList, fetchNextPage } = useAutocompleteOptionsList(
        {
            catalog: CatalogsConfig[catalogName],
            catalogName,
            autocomplete,
            extraFilters,
            isDisabled: disabled || !menuOpenState
        }
    );

    const id = useId();

    const handleScroll = useCallback(
        (e) => {
            const { scrollTop, offsetHeight, scrollHeight } = e.target;
            if (Math.round(scrollTop) + offsetHeight >= scrollHeight) {
                if (!isFetching) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching]
    );

    useEffect(() => {
        const element = document
            .getElementById(id)
            .querySelector('[role="listbox"]');

        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [id, handleScroll]);

    const { isFetchingValue, valuesMap, updateValuesMap } =
        useAutocompleteValue({
            catalog: CatalogsConfig[catalogName],
            fieldValue: useFullModel
                ? value?.[CatalogsConfig[catalogName].valueField]
                : value,
            isDisabled: useFullModel,
            fieldName: CatalogsConfig[catalogName].valueField
        });

    const options = useMemo(() => {
        if (pagedList) {
            return reduce(
                pagedList.pages,
                (result, page) => concat(result, page.content),
                []
            );
        }
        return [];
    }, [pagedList]);

    const getOptionValue = (option) =>
        option[CatalogsConfig[catalogName].valueField];

    const getOptionLabel = (option) => {
        return CatalogsConfig[catalogName].renderOptionLabel
            ? CatalogsConfig[catalogName].renderOptionLabel(option)
            : CatalogsConfig[catalogName].optionDisplayFields
                  .map((field) => option[field])
                  .join(' | ');
    };

    const getSelectedValue = () => {
        if (useFullModel) {
            return value;
        }
        const key =
            typeof value === 'object'
                ? value?.[CatalogsConfig[catalogName].valueField]
                : value;

        if (!key || !valuesMap[key]) {
            return null;
        }
        return valuesMap[key];
    };

    const handleChange = (e) => {
        updateValuesMap(e);
        if (isNil(e) || !e[CatalogsConfig[catalogName].valueField]) {
            onChange(null);
            return;
        }

        onChange(e[CatalogsConfig[catalogName].valueField], e);
    };

    return (
        <BaseSelect
            id={id}
            value={getSelectedValue()}
            isLoading={isFetching || isFetchingValue}
            options={options}
            name={name}
            placeholder={placeholder}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isDisabled={disabled}
            isSearchable={isSearchable}
            isClearable={isClearable}
            filterOption={() => true}
            onChange={handleChange}
            onInputChange={debounce(setAutocomplete, 300)}
            onMenuOpen={() => setMenuOpenState(true)}
            onMenuClose={() => setMenuOpenState(false)}
            error={error}
            menuPlacement={menuPlacement}
            menuPosition={menuPosition}
            menuIsOpen={menuIsOpen}
        />
    );
};
