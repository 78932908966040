import { Header } from '../header/Header';
import styles from './Layout.module.scss';

export const Layout = ({ children }) => {
    return (
        <div className={styles.layout}>
            <Header />
            <main className={styles.main}>
                <div className={styles.content}>{children}</div>
            </main>
        </div>
    );
};
