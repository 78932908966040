import { ACTIVE, AOF, REJECTED, TELEPHONOGRAM } from 'modules/Defects/consts';

export const getStatusOptions = (t) => {
    return [
        {
            label: t('defects:status.ACTIVE'),
            value: ACTIVE
        },
        {
            label: t('defects:status.REJECTED'),
            value: REJECTED
        },
        {
            label: t('defects:status.AOF'),
            value: AOF
        },
        {
            label: t('defects:status.TELEPHONOGRAM'),
            value: TELEPHONOGRAM
        }
    ];
};
