import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'sam-ui-kit';
import { createModal } from 'src/core/modal';

import { notificateErrorResponse } from 'utils/serverErrors';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormDatePickerField } from 'components/Form/FormDatePickerField';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';
import { RequiredField } from 'components/Form/rules';

import { useRefetchDashboard } from 'modules/Situations/hooks/useRefetchDashboard';
import {
    getSituationDetails,
    updateSituation
} from 'modules/Situations/services';
import { getSituationDepartureModel } from 'modules/Situations/utils';

export const DepartureModal = createModal(
    (props) => {
        const { id } = props;
        const { t } = useTranslation();
        const refetch = useRefetchDashboard();

        const formData = useQuery({
            queryKey: ['getSituationDetails', id],
            queryFn: () => getSituationDetails(id),
            enabled: !!id
        });

        const methods = useForm({
            defaultValues: {
                departure: 'park'
            }
        });

        const mutation = useMutation({
            mutationFn: (data) =>
                updateSituation(id, getSituationDepartureModel(data)),
            onSuccess: () => {
                refetch();
                props.onOk();
            },
            onError: (response) => notificateErrorResponse(response, t)
        });

        useEffect(() => {
            if (formData.data) {
                methods.reset(getSituationDepartureModel(formData.data));
            }
        }, [formData.data, methods]);

        const isDepStation = useWatch({
            name: 'isDepStation',
            control: methods.control
        });

        return (
            <Modal
                title={'Добавить убытие'}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'md'}
                {...props}
            >
                <Form
                    methods={methods}
                    onSubmit={mutation.mutate}
                    disabled={mutation.isPending || formData.isLoading}
                >
                    <Modal.ModalBody size={props.isMobile && 'full'}>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormDatePickerField
                                        label={t(
                                            'situations:date_time_departure'
                                        )}
                                        name="departureDate"
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormSegmentControlField
                                        label={t('situations:departure')}
                                        name="isDepStation"
                                        segmentsConfig={[
                                            {
                                                label: t('situations:park'),
                                                value: false
                                            },
                                            {
                                                label: t('station'),
                                                value: true
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    {isDepStation ? (
                                        <FormAutocomplete
                                            name="departureStation"
                                            label={t('station')}
                                            catalogName="STATIONS"
                                            useFullModel={true}
                                            rules={RequiredField}
                                        />
                                    ) : (
                                        <FormAutocomplete
                                            name="departurePark"
                                            label={t('situations:park')}
                                            catalogName="station_parks"
                                            useFullModel={true}
                                            rules={RequiredField}
                                        />
                                    )}
                                </Col>
                                {isDepStation && (
                                    <Col span={12}>
                                        <FormAutocomplete
                                            clearable
                                            name="escortGuard"
                                            label={t('situations:escort')}
                                            catalogName="GUARDS"
                                            useFullModel={true}
                                            placeholder={t(
                                                'applications:choose_security'
                                            )}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Modal.CancelButton
                                label={t('actions.cancel')}
                                block={props.isMobile}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.save')}
                                type="submit"
                                block={props.isMobile}
                                disabled={
                                    mutation.isPending || formData.isLoading
                                }
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        displayName: 'DepartureModal'
    }
);
