// import { FallbackPage } from 'components/FallbackPage/FallbackPage';
// import { ForbiddenIcon } from 'components/icons/ForbiddenIcon';

export const ForbiddenPage = () => (
    <div>forbidden page</div>
    // <FallbackPage
    //     icon={<ForbiddenIcon />}
    //     title="forbidden_title"
    //     subTitle="forbidden_sub_title"
    // />
);
