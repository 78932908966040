import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router/router';
import { NotificationsProvider, SamUIKitConfig } from 'sam-ui-kit';

import { queryClient } from 'core/queryClient';
import { store } from 'core/store';

import { useSamUiKitConfig } from 'hooks/useSamUiKitConfig';

import './index.scss';

function App() {
    const samUiKitConfig = useSamUiKitConfig();
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <SamUIKitConfig.Provider value={samUiKitConfig}>
                    <NotificationsProvider
                        containerId="notifications-root"
                        notificationDisplayTime={5000}
                    />
                    <RouterProvider router={router} />
                </SamUIKitConfig.Provider>
            </QueryClientProvider>
        </Provider>
    );
}

export default App;
