import { useTranslation } from 'react-i18next';
import { Spin } from 'sam-ui-kit';
import { formatDate } from 'src/utils/dateUtils';

import { Card } from 'components/Card/Card';
import { CardValue } from 'components/CardValue/CardValue';

import { DefectStatus } from 'modules/Defects/components/DefectStatus';

import styles from './DefectCardsList.module.scss';

export const DefectCardsList = ({ data = [], isFetching }) => {
    const { t } = useTranslation();
    return (
        <Spin spinning={isFetching} text={t('loading')} fillOut={true}>
            <div className={styles.wrapper}>
                {data.map((item) => (
                    <Card
                        key={item.id}
                        title={<span className={styles.title}>{item.id}</span>}
                        status={<DefectStatus status={item.status} />}
                        hideActions={true}
                    >
                        <CardValue
                            label={t('station')}
                            value={item.station?.name || ''}
                        />
                        <CardValue
                            label={t('defects:park')}
                            value={item.park?.nameRu || ''}
                        />
                        <CardValue
                            label={t('date')}
                            value={formatDate(item.createdAt)}
                        />
                        <CardValue
                            label={t('defects:security')}
                            value={item.guardUsername}
                        />
                        <CardValue
                            label={t('defects:carriage')}
                            value={item.wagonCode}
                        />

                        <CardValue
                            label={t('description')}
                            value={item.defect?.name || ''}
                        />
                    </Card>
                ))}
            </div>
        </Spin>
    );
};
