import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { ArrowLeftCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { Form } from 'components/Form/Form';

import { SituationAcceptGuard } from 'modules/Situations/components/SituationAcceptGuard';
import { SituationCard } from 'modules/Situations/components/SituationCard';
import { WagonCard } from 'modules/Situations/components/WagonCard/WagonCard';
import { WagonStatistic } from 'modules/Situations/components/WagonStatistic/WagonStatistic';
import { useIsSituationAcceptable } from 'modules/Situations/hooks/useIsSituationAcceptable';
import {
    acceptSituationWagons,
    getSituationWagons,
    saveSituationWagons
} from 'modules/Situations/services';
import {
    wagonFormDataToRequest,
    wagonRequestToFormData
} from 'modules/Situations/utils';

import styles from './SituationDetailsPage.module.scss';

export const SituationDetailsPage = () => {
    const { isMobile } = useScreenSize();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams('id');

    const [acceptGuard, setAcceptGuard] = useState();
    const [acceptGuardValidation, setAcceptGuardValidation] = useState();

    const { data, refetch, isLoading } = useQuery({
        queryFn: () => getSituationWagons(id),
        queryKey: ['getSituationDetails', id],
        initialData: {
            id,
            wagons: []
        }
    });

    const methods = useForm({
        defaultValues: {
            id,
            wagons: []
        }
    });

    const { fields } = useFieldArray({
        control: methods.control,
        name: 'wagons',
        keyName: 'fieldId'
    });

    useEffect(() => {
        if (data.wagons) {
            methods.reset({
                id: data.id,
                wagons: data.wagons.map(wagonRequestToFormData)
            });
        }

        if (data.acceptGuard) {
            setAcceptGuard(data.acceptGuard);
        }
    }, [data, methods, setAcceptGuard]);

    const wagons = useWatch({
        name: 'wagons',
        control: methods.control,
        defaultValue: []
    });

    const saveMutation = useMutation({
        mutationFn: (data) => {
            return saveSituationWagons({
                ...data,
                wagons: data.wagons
                    .filter((item) => item.acceptable)
                    .map(wagonFormDataToRequest)
            });
        },
        onSuccess: refetch
    });

    const acceptMutation = useMutation({
        mutationFn: acceptSituationWagons,
        onSuccess: refetch
    });

    const isSituationAcceptable = useIsSituationAcceptable(data);

    const handleBack = () => {
        //TODO TEAM-107 разобраться с сохранением фильтров при переходе назад
        navigate('/');
    };

    const handleAcceptGuardChange = (guard) => {
        setAcceptGuard(guard);
        if (guard) {
            setAcceptGuardValidation(null);
        }
    };

    const handleAcceptClick = () => {
        if (!acceptGuard) {
            setAcceptGuardValidation(t('formErrors.requiredField'));
        } else {
            setAcceptGuardValidation(null);
            acceptMutation.mutate({
                id: data.id,
                acceptGuard
            });
        }
    };

    const renderActions = () => {
        if (data.status === 'ACCEPTED') {
            return null;
        }
        return isSituationAcceptable && !methods.formState.isDirty ? (
            <Button
                label={t('actions.accept2')}
                block={true}
                disabled={acceptMutation.isPending || isLoading}
                variant="primary"
                size="lg"
                onClick={handleAcceptClick}
            />
        ) : (
            <Button
                label={t('actions.save')}
                block={true}
                disabled={saveMutation.isPending || isLoading}
                variant="primary"
                size="lg"
                type="submit"
            />
        );
    };

    return (
        <div
            className={clsx(styles.situationTrain, {
                [styles.mobileView]: isMobile
            })}
        >
            <h1 className={styles.pageTitle}>
                <ArrowLeftCircle
                    color="#888888"
                    size={26}
                    onClick={handleBack}
                />
                {t('train')} №{data.nomPoezd}
            </h1>

            {isMobile && (
                <Row gutter={20}>
                    <Col span={12}>
                        <SituationCard data={data} />
                    </Col>
                </Row>
            )}
            <WagonStatistic
                isMobile={isMobile}
                rejectedCarriagesLength={0}
                totalWagonCount={data.totalWagonCount}
                data={data}
                formWagons={wagons}
            />
            <Form
                methods={methods}
                onSubmit={saveMutation.mutate}
                disabled={saveMutation.isPending || data.isLoading}
            >
                <Row gutter={[20, 24]}>
                    <Col span={9} sm={12}>
                        <Row gutter={[16, 16]}>
                            {fields.map((field, index) => (
                                <Col span={4} sm={12} key={field.fieldId}>
                                    <WagonCard
                                        index={index}
                                        wagon={data.wagons[index]}
                                        situationId={data.id}
                                        refetch={refetch}
                                        situationAccepted={
                                            data.status === 'ACCEPTED'
                                        }
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col span={3} sm={12}>
                        <div className={styles.situationInfo}>
                            {!isMobile && <SituationCard data={data} />}

                            {((isSituationAcceptable &&
                                !methods.formState.isDirty) ||
                                data.status === 'ACCEPTED') && (
                                <SituationAcceptGuard
                                    label={t('situations:security_person')}
                                    onChange={handleAcceptGuardChange}
                                    value={acceptGuard}
                                    errorMessage={acceptGuardValidation}
                                    disabled={data.status === 'ACCEPTED'}
                                />
                            )}
                            {renderActions()}
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
