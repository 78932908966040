import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'sam-ui-kit';

import { Form } from 'components/Form/Form';
import { FormInputField } from 'components/Form/FormInputField';
import { RequiredField } from 'components/Form/rules';
import { QorgauLogo } from 'components/icons/QorgauLogo';

import { login } from 'modules/Auth/actions';

import styles from './LoginPage.module.scss';

export const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthLoading, fieldErrors } = useSelector((state) => state.auth);
    const [searchParams] = useSearchParams();
    const queryClient = useQueryClient();

    const methods = useForm({
        defaultValues: defaultValues
    });

    const onSubmit = (data, e) => {
        e.preventDefault();

        dispatch(
            login({
                username: data.username,
                password: data.password
            })
        ).then(() => {
            navigate(searchParams.get('referrer'));
            queryClient.resetQueries();
        });
    };

    return (
        <div className={styles.loginPage}>
            <div className={styles.loginBlock}>
                <div className={styles.logo}>
                    <QorgauLogo />
                </div>

                <span className={styles.text}>{t('login.authorization')}</span>

                <Form
                    className={styles.form}
                    methods={methods}
                    onSubmit={onSubmit}
                    disabled={isAuthLoading}
                    fieldErrors={fieldErrors}
                >
                    <FormInputField
                        name="username"
                        autoComplete="on"
                        placeholder={t('login.login')}
                        rules={RequiredField}
                    />
                    <FormInputField
                        name="password"
                        type="password"
                        placeholder={t('login.password')}
                        autoComplete="on"
                        rules={RequiredField}
                    />
                    <div className={styles.actions}>
                        <Button
                            label={t('login.signin')}
                            variant="primary"
                            type="submit"
                            size="lg"
                            disabled={isAuthLoading}
                            loading={isAuthLoading}
                            block
                        />
                        <Button
                            label={t('login.forgot_password')}
                            variant="default"
                            type="submit"
                            size="lg"
                            disabled={isAuthLoading}
                            block
                        />
                    </div>
                </Form>
            </div>
        </div>
    );
};

const defaultValues = {
    username: '',
    password: ''
};
