import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useEffect, useRef } from 'react';
import { useSize } from 'src/hooks/useSize';

import { isMobile } from 'utils/mobileUtils';

export const createModal = (Component, options = {}) => {
    const { displayName, chainOnClose = false } = options;

    function ModalWrapper(props) {
        const modal = useModal();

        const header = useRef(document.getElementById('header'));
        const { showSmall } = useSize(header);
        useEffect(() => {
            const body = document.body;
            const header = document.getElementById('header');
            const navigation = document.getElementById('navigation');
            body.classList.add('overflow-hidden');
            if (
                document.body.clientHeight <
                    document.documentElement.scrollHeight &&
                !isMobile()
            ) {
                body.classList.add('p-right-10');
                header.classList.add('p-right-17');
                navigation?.classList.add('p-right-17');
            }
            return () => {
                body.classList.remove('p-right-10');
                body.classList.remove('overflow-hidden');
                header.classList.remove('p-right-17');
                navigation?.classList.remove('p-right-17');
            };
        }, []);

        const handleOk = (args) => {
            chainOnClose && modal.resolve(args);
            modal.remove();
        };

        const handleCancel = (args) => {
            chainOnClose && modal.reject(args);
            modal.remove();
        };

        if (showSmall === undefined) {
            return null;
        }

        return (
            <Component
                onCancel={handleCancel}
                onOk={handleOk}
                {...props}
                isMobile={showSmall}
            />
        );
    }

    if (displayName) {
        ModalWrapper.displayName = displayName;
    }

    return NiceModal.create(ModalWrapper);
};

export const registerModal = (id, Component, props) => {
    NiceModal.register(id, Component, props);
};

/**
 * @param ModalComponentOrId Компонент модалки или ID модалки зарегистрированной через registerModal
 * @param modalArgument Передаваемые аргументы
 *
 * @returns {Promise<any> | undefined} Если модалка создавалась с параметром chainOnClose=true,
 *                                     необходимо обработать промис
 */
export const showModal = (ModalComponentOrId, modalArgument) => {
    return NiceModal.show(ModalComponentOrId, modalArgument);
};
