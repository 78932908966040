import clsx from 'clsx';
import { ArrowLeftCircle, Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { AWAITING_ACCEPTANCE } from 'modules/Applications/consts';

import styles from './ReceptionPageHeader.module.scss';

export const ReceptionPageHeader = ({
    applicationData = {},
    applicationIsFetching
}) => {
    const navigate = useNavigate();
    const { isMobile } = useScreenSize();

    const { t } = useTranslation();

    const handleNavigate = () => {
        if (applicationData.acceptanceStation) {
            navigate(
                `/applications?customFilters=${JSON.stringify({
                    appearance_station_code_eq:
                        applicationData.acceptanceStation.code
                })}`
            );
        } else {
            navigate('/applications');
        }
    };

    const handleAddCarriage = () => {
        navigate(`carriage`);
    };

    return (
        <div className={styles.header}>
            <div>
                <ArrowLeftCircle
                    size={21}
                    color="#888888"
                    onClick={handleNavigate}
                    cursor="pointer"
                />
                <span
                    className={clsx(styles.title, {
                        [styles.sm]: isMobile
                    })}
                >
                    {t('applications:repection_title')}
                </span>
            </div>
            <div>
                <Button
                    label={t('applications:add_carriage')}
                    variant="primary"
                    size="lg"
                    icon={<Plus size={16} />}
                    onClick={handleAddCarriage}
                    disabled={
                        applicationIsFetching ||
                        applicationData.status !== AWAITING_ACCEPTANCE
                    }
                />
            </div>
        </div>
    );
};
