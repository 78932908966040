import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Space } from 'sam-ui-kit';
import { formatDate } from 'src/utils/dateUtils';

import { useScreenSize } from 'hooks/useScreenSize';

import { Divider } from 'components/Divider/Divider';

import { ApplicationCarriageStatus } from 'modules/History/components/ApplicationCarriageStatus';
import { getHistoryApps } from 'modules/History/services';

import styles from './ApplicationStatistics.module.scss';

const selectFirstElement = (data) => data[0];

export const ApplicationStatistics = () => {
    const { isMobile } = useScreenSize();
    const { t } = useTranslation();
    const { appWagonId, id } = useParams();

    const { data } = useQuery({
        queryKey: ['getHistoryApps', id],
        queryFn: () =>
            getHistoryApps({
                app_id_eq: id,
                app_wagon_id_eq: appWagonId
            }),
        select: selectFirstElement,
        enabled: !!id,
        initialData: {}
    });

    return (
        <h3
            className={clsx(styles.title, {
                [styles.mobileView]: isMobile
            })}
        >
            {isMobile ? (
                <>
                    <span className={styles.bold}>
                        {`${t('history:application')} №${data?.appId}`}
                    </span>
                    <span>
                        {`${data?.acceptanceStation?.name || ''} - ${data?.stanNazn?.name || ''}`}
                    </span>
                    <Divider classNames={styles.divider} />
                    <span className={styles.greyText}>
                        {`${t('history:application_date')}: ${formatDate(data?.dt)}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('history:detached_date')}: ${formatDate(data?.detachedAt)}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('cargo')}: ${data?.cargoType?.name || ''}`}
                    </span>
                </>
            ) : (
                <Space displayDivider={true} gap={24}>
                    <span className={styles.bold}>
                        {`${t('history:application')} №${data?.appId}`}
                    </span>
                    <span>
                        {`${data?.acceptanceStation?.name || ''} - ${data?.stanNazn?.name || ''}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('history:application_date')}: ${formatDate(data?.dt)}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('history:detached_date')}: ${formatDate(data?.detachedAt)}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('cargo')}: ${data?.cargoType?.name || ''}`}
                    </span>
                </Space>
            )}
            <ApplicationCarriageStatus status={data?.status} />
        </h3>
    );
};
