import clsx from 'clsx';
import { ArrowLeftCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { useScreenSize } from 'hooks/useScreenSize';

import styles from './WagonDetailsPageHeader.module.scss';

export const WagonDetailsPageHeader = ({ wagonCode }) => {
    const { isMobile } = useScreenSize();

    const { t } = useTranslation();
    const { id } = useParams();

    return (
        <div className={styles.header}>
            <HashLink to={`/situations/${id}#${wagonCode}`}>
                <ArrowLeftCircle size={21} color="#888888" cursor="pointer" />
            </HashLink>

            <span
                className={clsx(styles.title, {
                    [styles.sm]: isMobile
                })}
            >
                {`${t('carriage')} ${wagonCode}`}
            </span>
        </div>
    );
};
