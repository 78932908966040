import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { Card } from 'components/Card/Card';
import { CardValue } from 'components/CardValue/CardValue';

import { SituationsCarriageViewForm } from 'modules/Situations/components/SituationsCarriageViewForm/SituationsCarriageViewForm';

import { CarriageAttributes } from '../CarriageAttributes/CarriageAttributes';

export const CarriageView = ({ data = {}, situationId }) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    return (
        <Row gutter={[16, 16]}>
            <Col span={3} md={12} sm={12}>
                <Card
                    title={t('applications:carriage')}
                    hideActions={true}
                    size="md"
                >
                    <CardValue
                        label={t('applications:carriage_number')}
                        value={data.wagonCode}
                    />
                    <CardValue
                        label={t('applications:comment')}
                        value={data.defect?.nameRu}
                    />
                    <CardValue label={t('note')} value={data.remark} />
                    <CardValue
                        label={t('status')}
                        value={
                            data.initialStatus
                                ? t(
                                      `applications:status_cargo.${data.initialStatus}`
                                  )
                                : ''
                        }
                    />
                </Card>
                {!isNil(situationId) && !isMobile && (
                    <SituationsCarriageViewForm
                        wagonId={data.id}
                        situationId={situationId}
                    />
                )}
            </Col>
            <Col span={9} md={12} sm={12}>
                <CarriageAttributes
                    fields={data.attributes || []}
                    isDisabled={true}
                />
                {!isNil(situationId) && isMobile && (
                    <SituationsCarriageViewForm
                        wagonId={data.id}
                        situationId={situationId}
                    />
                )}
            </Col>
        </Row>
    );
};
