import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { ArrowLeftCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, SegmentControl } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { RequiredField } from 'components/Form/rules';

import { SituationCard } from 'modules/Situations/components/SituationCard';
import { WagonCard } from 'modules/Situations/components/WagonCard/WagonCard';
import { WagonStatistic } from 'modules/Situations/components/WagonStatistic/WagonStatistic';
import { getSituationWagons } from 'modules/Situations/services';
import { wagonRequestToFormData } from 'modules/Situations/utils';

import styles from './HistorySituationDetailsPage.module.scss';

export const HistorySituationDetailsPage = () => {
    const { isMobile } = useScreenSize();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { situationId, appWagonId } = useParams();

    const [tab, setTab] = useState('current');

    const { data } = useQuery({
        queryFn: () => getSituationWagons(situationId),
        queryKey: ['getSituationDetails', situationId],
        initialData: {
            wagons: []
        }
    });

    const methods = useForm({
        defaultValues: {
            id: null,
            wagons: []
        }
    });

    useEffect(() => {
        if (data.wagons) {
            methods.reset({
                id: data.id,
                wagons: data.wagons
                    .filter((_, index) =>
                        tab === 'current'
                            ? data.wagons[index]?.appWagonId === +appWagonId
                            : true
                    )
                    .map(wagonRequestToFormData)
            });
        }
    }, [data, methods, appWagonId, tab]);

    useEffect(() => {
        return () => {
            methods.reset();
        };
    }, [methods]);

    const { fields } = useFieldArray({
        control: methods.control,
        name: 'wagons',
        keyName: 'fieldId'
    });

    const wagons = useWatch({
        name: 'wagons',
        control: methods.control,
        defaultValue: []
    });

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div
            className={clsx(styles.situationTrain, {
                [styles.mobileView]: isMobile
            })}
        >
            <h1 className={styles.pageTitle}>
                <ArrowLeftCircle
                    color="#888888"
                    size={26}
                    onClick={handleBack}
                />
                {t('train')} №{data.nomPoezd}
            </h1>

            {isMobile && (
                <Row gutter={20}>
                    <Col span={12}>
                        <SituationCard data={data} />
                    </Col>
                </Row>
            )}
            <WagonStatistic
                isMobile={isMobile}
                rejectedCarriagesLength={0}
                totalWagonCount={data.totalWagonCount}
                data={data}
                formWagons={wagons}
            />

            <div className="m-bot-16">
                <Row>
                    <Col span={2}>
                        <SegmentControl
                            value={tab}
                            segmentsConfig={[
                                {
                                    label: t('history:current_carriage'),
                                    value: 'current'
                                },
                                {
                                    label: t('history:all_carriages'),
                                    value: 'all'
                                }
                            ]}
                            variant="default"
                            onChange={setTab}
                        />
                    </Col>
                </Row>
            </div>

            <Form methods={methods} disabled={data.isLoading}>
                <Row gutter={[20, 24]}>
                    <Col span={9} sm={12}>
                        <Row gutter={[16, 16]}>
                            {fields.map((field, index) => (
                                <Col span={4} sm={12} key={field.fieldId}>
                                    <WagonCard
                                        index={index}
                                        situationId={data.id}
                                        wagon={data.wagons[index]}
                                        field={field}
                                        isDisabled={true}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col span={3} sm={12}>
                        <div className={styles.situationInfo}>
                            {!isMobile && <SituationCard data={data} />}
                            <FormAutocomplete
                                name="arrivalStation"
                                label={t('situations:security_person')}
                                catalogName="GUARDS"
                                useFullModel={true}
                                disabled={true}
                                rules={RequiredField}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
