import { useQueryParam } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';
import { useDataRequest } from 'core/queries/useDataRequest';

import { useSort } from 'hooks/useSort';

import { getHistoryApps } from 'modules/History/services';

import { HistoryHeader } from '../components/HistoryHeader/HistoryHeader';
import { HistoryTable } from '../components/HistoryTable/HistoryTable';
import { historyInitialSort } from '../consts';

export const HistoryPage = () => {
    const [customFilters] = useQueryParam('customFilters', CustomFilterParam);
    const [sort, setSort] = useSort(historyInitialSort);

    const historyApps = useDataRequest('historyApps', getHistoryApps, {
        customFilters,
        sort
    });

    return (
        <>
            <HistoryHeader isLoading={historyApps.isLoading} />
            <HistoryTable
                sort={sort}
                setSort={setSort}
                data={historyApps.data}
                isLoading={historyApps.isLoading}
            />
        </>
    );
};
