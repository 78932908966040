import { useTranslation } from 'react-i18next';
import { Col, Row } from 'sam-ui-kit';

import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormCounterField } from 'components/Form/FormCounterField';
import { FormInputField } from 'components/Form/FormInputField';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';

import styles from './TwistAttributesItem.module.scss';

export const TwistAttributesItem = ({
    onDelete,
    attributeIndex,
    fieldPath = ''
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.twist}>
            <div className={styles.header}>
                <span className={styles.title}>{t('applications:twist')}</span>
                <span
                    className={styles.delete}
                    onClick={() => onDelete(attributeIndex)}
                >
                    {t('actions.delete')}
                </span>
            </div>
            <Row gutter={[12, 12]}>
                <Col span={5} sm={12}>
                    <FormSegmentControlField
                        name={`${fieldPath}.isProductionTwist`}
                        segmentsConfig={[
                            {
                                label: t('applications:production'),
                                value: true
                            },
                            {
                                label: t('applications:manual'),
                                value: false
                            }
                        ]}
                        noHintSpace={true}
                    />
                </Col>

                <Col span={4} sm={12}>
                    <FormCounterField
                        name={`${fieldPath}.coilsNumber`}
                        placeholder={t('applications:twist_count')}
                    />
                </Col>
                <Col span={3} sm={12}>
                    <FormAutocomplete
                        clearable
                        name={`${fieldPath}.twistColor`}
                        catalogName="ATTRIBUTES_COLOR"
                        placeholder={t('applications:color')}
                        useFullModel={true}
                    />
                </Col>
                <Col span={5} sm={12}>
                    <FormAutocomplete
                        clearable
                        name={`${fieldPath}.location`}
                        placeholder={t('applications:location')}
                        catalogName="LOCATION"
                        useFullModel={true}
                    />
                </Col>
                <Col span={7} sm={12}>
                    <FormInputField
                        name={`${fieldPath}.remark`}
                        placeholder={t('applications:remark')}
                        noHintSpace={true}
                    />
                </Col>
            </Row>
        </div>
    );
};
