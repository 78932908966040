import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Space } from 'sam-ui-kit';

import { Divider } from 'components/Divider/Divider';

import styles from './WagonStatistic.module.scss';

export const WagonStatistic = ({ isMobile, data, formWagons }) => {
    const { t } = useTranslation();
    const removedUnderProtectionCount = data.wagons.filter(
        ({ detached }) => detached === true
    ).length;

    const initialRejected = data.wagons.filter(
        ({ initialWagonAcceptanceStatus }) =>
            initialWagonAcceptanceStatus === 'REJECTED'
    ).length;

    const prevRejected = data.wagons.filter(
        ({ wagonAcceptanceStatusInLastSituation }) =>
            wagonAcceptanceStatusInLastSituation === false
    ).length;

    const acceptedCount = formWagons.filter(
        ({ wagonAcceptanceInSituation }) => {
            return wagonAcceptanceInSituation === 'true';
        }
    ).length;

    const notAcceptedCount = formWagons.filter(
        ({ wagonAcceptanceInSituation }) => {
            return wagonAcceptanceInSituation === 'false';
        }
    ).length;

    const withoutAcceptance = data.wagons.filter((wagon) => {
        return !('appWagonId' in wagon);
    }).length;

    return (
        <h3
            className={clsx(styles.title, {
                [styles.mobileView]: isMobile
            })}
        >
            {isMobile ? (
                <>
                    <span>
                        {t('situations:accepted_carriages')}: {acceptedCount}/
                        {data.guardedWagonsCount}
                    </span>
                    <Divider classNames={styles.divider} />
                    <span className={styles.greyText}>
                        {`${t('situations:initial_acceptance_rejected')}: ${initialRejected}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:prev_acceptance_rejected')}: ${prevRejected}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:removed_under_protection')}: ${removedUnderProtectionCount}`}
                    </span>
                    <span className={styles.greyText}>
                        {t('actions.not_accepting')}: {notAcceptedCount}
                    </span>
                    <span className={styles.greyText}>
                        {t('situations:without_accepting')}: {withoutAcceptance}
                    </span>
                </>
            ) : (
                <Space displayDivider={true} gap={24}>
                    <span>
                        {t('situations:accepted_carriages')}: {acceptedCount}/
                        {data.guardedWagonsCount}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:initial_acceptance_rejected')}: ${initialRejected}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:prev_acceptance_rejected')}: ${prevRejected}`}
                    </span>
                    <span className={styles.greyText}>
                        {`${t('situations:removed_under_protection')}: ${removedUnderProtectionCount}`}
                    </span>
                    <span className={styles.greyText}>
                        {t('actions.not_accepting')}: {notAcceptedCount}
                    </span>
                    <span className={styles.greyText}>
                        {t('situations:without_accepting')}: {withoutAcceptance}
                    </span>
                </Space>
            )}
        </h3>
    );
};
