import ContentLoader from 'react-content-loader';

export const SkeletonLoader = (props) => {
    const { children, ...restProps } = props;
    return (
        <ContentLoader
            {...restProps}
            foregroundColor="#D9D7D7"
            backgroundColor="#E8E7E9"
        >
            {children}
        </ContentLoader>
    );
};
