import { useTranslation } from 'react-i18next';
import { NavLink, useMatch } from 'react-router-dom';

import styles from './MobileMainMenu.module.scss';

export const MobileMainMenu = ({ menuItems, onClick }) => {
    const matcher = useMatch('/:module?/*');
    const { t } = useTranslation();

    return (
        <ul className={styles.mobileMenu}>
            {menuItems.map((item) => {
                return (
                    <span key={item.url}>
                        <NavLink
                            to={item.url}
                            className={() => {
                                if (
                                    matcher.params.module === undefined &&
                                    item.module === 'situations'
                                ) {
                                    return styles.active;
                                }
                                return matcher.params.module === item.module
                                    ? styles.active
                                    : null;
                            }}
                            onClick={onClick}
                        >
                            {item.icon}
                            {t(item.module)}
                        </NavLink>
                    </span>
                );
            })}
        </ul>
    );
};
