import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const lng = localStorage.getItem('language') || 'ru';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng,
        ns: [
            'common',
            'applications',
            'situations',
            'defects',
            'history',
            'calendar',
            'backendValidation'
        ],
        defaultNS: 'common',
        fallbackLng: 'ru',
        debug: false,
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
    });

export default i18n;
