import clsx from 'clsx';
import { ArrowLeftCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useScreenSize } from 'hooks/useScreenSize';

import styles from './CarriagePageHeader.module.scss';

export const CarriagePageHeader = () => {
    const navigate = useNavigate();

    const { isMobile } = useScreenSize();

    const { t } = useTranslation();
    const { id } = useParams();

    const handleNavigate = () => {
        navigate(`/applications/reception/${id}`);
    };

    return (
        <div className={styles.header}>
            <ArrowLeftCircle
                size={21}
                color="#888888"
                onClick={handleNavigate}
                cursor="pointer"
            />
            <span
                className={clsx(styles.title, {
                    [styles.sm]: isMobile
                })}
            >
                {t('applications:repection_title')}
            </span>
        </div>
    );
};
