import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { useScreenSizeObserver } from 'hooks/useScreenSizeObserver';

import { Layout } from '../layouts/layout/Layout';

export const AuthRequired = () => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    useScreenSizeObserver();
    return isLoggedIn ? (
        <Layout>
            <Outlet />
        </Layout>
    ) : (
        <Navigate
            to={`/login?referrer=${window.location.pathname}${window.location.search}`}
            replace
        />
    );
};
