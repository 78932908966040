import clsx from 'clsx';

import styles from './StatusText.module.scss';

export const StatusText = ({ label, variant, noBackground, textAlign }) => {
    const classNames = clsx(styles.title, {
        [styles['title_orange']]: variant === 'orange',
        [styles['title_red']]: variant === 'red',
        [styles['title_grey']]: variant === 'grey',
        [styles['title_green']]: variant === 'green',
        [styles['title_right']]: textAlign === 'right',
        [styles.noBackground]: noBackground
    });
    return (
        <div className={classNames}>
            {!noBackground && <div className={styles.icon}></div>}
            <span className={styles.label}>{label}</span>
        </div>
    );
};
