import { subDays } from 'date-fns';
import { SYSTEM_DATE_FORMAT } from 'settings';

import api from 'core/api';
import {
    DataRequestDetailsNormalizer,
    DefaultNormalizer
} from 'core/normalizers/DataRequest';

import { formatDate } from 'utils/dateUtils';

export const loadWagonInfoFromFile = (file) => {
    const request = new FormData();
    request.append('file', file, file.name);
    return api.post(
        '/cargo-asoup/api/cargo-asoup/parse-natural-sheet',
        request,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            normalizer: DefaultNormalizer
        }
    );
};

export const createSituation = (data) => {
    return api.post('/cargo-transfer/api/cargo-transfers/situations', data);
};

export const editSituation = (data) => {
    return api.put(
        `/cargo-transfer/api/cargo-transfers/situations/${data.id}`,
        data
    );
};

export const updateSituation = (id, data) => {
    return api.patch(
        `/cargo-transfer/api/cargo-transfers/situations/${id}`,
        data
    );
};

export const deleteSituation = (id) => {
    return api.delete(`/cargo-transfer/api/cargo-transfers/situations/${id}`);
};

export const getSituationsList = (params) => {
    return api.get('/qorgau-data/api/data-request/non-paged/cargo_situations', {
        params: {
            ...params,
            accepted_date_gte: formatDate(
                subDays(new Date(), 1),
                SYSTEM_DATE_FORMAT
            )
        },
        // params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getParkTypesList = (params) => {
    return api.get('/qorgau-mdm/api/data-request/park_types_ui', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getCargoEscortList = (params) => {
    return api.get('/qorgau-data/api/data-request/cargo_escort', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getCargoOncomingList = (params) => {
    return api.get('/qorgau-data/api/data-request/cargo_oncoming', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getSituationDetails = (id) => {
    return api.get(`/cargo-transfer/api/cargo-transfers/situations/${id}`, {
        normalizer: DefaultNormalizer
    });
};

export const getSituationWagons = (id) => {
    return api.get(
        `/cargo-transfer/api/cargo-transfers/situations/${id}/wagons`,
        {
            normalizer: DefaultNormalizer
        }
    );
};

export const saveSituationWagons = (data) => {
    return api.patch(
        `/cargo-transfer/api/cargo-transfers/situations/${data.id}`,
        data
    );
};

export const acceptSituationWagons = (data) => {
    return api.post(
        `/cargo-transfer/api/cargo-transfers/situations/${data.id}/accept`,
        data
    );
};

export const detachSituationWagons = ({ situationId, wagonId }) => {
    return api.post(
        `/cargo-transfer/api/cargo-transfers/situations/${situationId}/wagons/${wagonId}/detach`
    );
};
