import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'sam-ui-kit';
import { createModal } from 'src/core/modal';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormCounterField } from 'components/Form/FormCounterField';
import { FormDatePickerField } from 'components/Form/FormDatePickerField';
import { FormInputField } from 'components/Form/FormInputField';
import { RequiredField } from 'components/Form/rules';

import { getCargosList } from 'modules/Applications/services/services';

export const ApplicationsEditModal = createModal(
    (props) => {
        const { t } = useTranslation();
        const { id } = props;

        const methods = useForm({
            defaultValues: {
                dt: null,
                acceptanceStation: null,
                cargoType: null,
                applicant: null,
                wagonCount: null
            }
        });
        const { data, isFetching } = useQuery({
            queryKey: ['getCargosList', id],
            queryFn: () =>
                getCargosList({
                    id_eq: props.id
                }),
            enabled: !!id,
            initialData: []
        });

        useEffect(() => {
            if (data.length && props.id) {
                methods.reset(data[0]);
            }
        }, [data, methods, props.id]);

        const mutation = useMutation({
            mutationFn: (state) => {
                return props.onCreate(state);
            },
            onSuccess: props.onOk
        });

        return (
            <Modal
                title={t('actions.create')}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'md'}
                {...props}
            >
                <Form
                    methods={methods}
                    disabled={isFetching || mutation.isPending}
                    onSubmit={mutation.mutate}
                >
                    <Modal.ModalBody size={props.isMobile && 'full'}>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormDatePickerField
                                        label={t(
                                            'applications:application_date'
                                        )}
                                        placeholder={t(
                                            'applications:choose_date'
                                        )}
                                        name="dt"
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="acceptanceStation"
                                        label={t('station')}
                                        catalogName="STATIONS"
                                        placeholder={t(
                                            'applications:choose_station'
                                        )}
                                        useFullModel={true}
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="cargoType"
                                        label={t('applications:cargo_category')}
                                        catalogName="CARGO_CODES"
                                        placeholder={t(
                                            'applications:choose_category'
                                        )}
                                        useFullModel={true}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormInputField
                                        label={t('applications:applicant')}
                                        placeholder={t(
                                            'applications:choose_applicant'
                                        )}
                                        name="applicant"
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormCounterField
                                        label={t(
                                            'applications:carriage_amount'
                                        )}
                                        placeholder={0}
                                        name="wagonCount"
                                        rules={RequiredField}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Modal.CancelButton
                                label={t('actions.cancel')}
                                block={props.isMobile}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.save')}
                                type="submit"
                                block={props.isMobile}
                                disabled={isFetching || mutation.isPending}
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        chainOnClose: true,
        displayName: 'ApplicationsEditModal'
    }
);
