import NiceModal from '@ebay/nice-modal-react';
import { createBrowserRouter } from 'react-router-dom';
import { AppModals } from 'src/AppModals';
import { QueryParamProvider } from 'use-query-params';

import { ApplicationPage } from 'modules/Applications/pages/ApplicationPage';
import { CarriagePage } from 'modules/Applications/pages/CarriagePage';
import { ReceptionPage } from 'modules/Applications/pages/ReceptionPage';
import { LoginPage } from 'modules/Auth/pages/LoginPage';
import { DefectsPage } from 'modules/Defects/pages/DefectsPage';
import { routes as historyRoutes } from 'modules/History/routes';
import { routes as situationsRoutes } from 'modules/Situations/routes';

import { AuthNotRequired } from './AuthNotRequired';
import { AuthRequired } from './AuthRequired';
import { RouterAdapter6 } from './RouterAdpater6';
import { ErrorPage } from './pages/ErrorPage';
import { ForbiddenPage } from './pages/ForbiddenPage';
import { NotFoundPage } from './pages/NotFoundPage';

export const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <QueryParamProvider adapter={RouterAdapter6}>
                <NiceModal.Provider>
                    <AppModals />
                    <AuthRequired />
                </NiceModal.Provider>
            </QueryParamProvider>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                children: situationsRoutes
            },
            {
                path: '/applications',
                children: [
                    {
                        index: true,
                        element: <ApplicationPage />
                    },
                    {
                        path: 'reception/:id',
                        element: <ReceptionPage />
                    },
                    {
                        path: 'reception/:id/carriage/:carriageId?',
                        element: <CarriagePage />
                    }
                ]
            },
            {
                path: '/defects',
                element: <DefectsPage />
            },
            {
                path: '/history',
                children: historyRoutes
            },
            {
                path: '/forbidden',
                element: <ForbiddenPage />
            },
            {
                path: '*',
                element: <NotFoundPage />
            }
        ]
    },
    {
        path: '/',
        element: <AuthNotRequired />,
        children: [
            {
                path: '/login',
                element: <LoginPage />
            }
        ]
    }
]);
