import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';
import { formatDate } from 'src/utils/dateUtils';

import { TextLink } from 'components/TextLink/TextLink';

import { DefectStatus } from 'modules/Defects/components/DefectStatus';
import { HistoryCarriageStatus } from 'modules/History/components/HistoryCarriageStatus/HistoryCarriageStatus';
import { HISTORY_PAGE_SIZE } from 'modules/History/consts';

import styles from './HistorySituationsTable.module.scss';

export const HistorySituationsTable = ({ sort, setSort, data, isLoading }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.table}>
            <Table
                loading={isLoading}
                data={data || []}
                columns={getColumnsConfig(t)}
                pageSize={HISTORY_PAGE_SIZE}
                sorting={sort}
                onSortingChange={setSort}
            />
        </div>
    );
};

const getColumnsConfig = (t) => {
    return [
        {
            header: '№',
            accessorKey: 'situationId',
            cell: ({ row }) => (
                <div className={styles.link}>
                    <TextLink
                        label={row.original.situationId}
                        url={`/history/situations/${row.original.situationId}/carriages/${row.original.appWagonId}`}
                    />
                </div>
            ),
            enableSorting: true
        },
        {
            header: t('station'),
            cell: ({ row }) => row.original.arrivalStation?.name,
            enableSorting: true
        },
        {
            header: t('history:park'),
            cell: ({ row }) => row.original.arrivalPark?.nameRu,
            enableSorting: true
        },
        {
            header: t('history:security'),
            accessorKey: 'guardFullname',
            enableSorting: true
        },
        {
            header: t('history:defect'),
            cell: ({ row }) => row.original.defect?.nameRu,
            enableSorting: true
        },
        {
            header: t('history:defect_status'),
            accessorKey: 'defectStatus',
            cell: ({ row }) =>
                row.original.defectStatus ? (
                    <DefectStatus
                        status={row.original.defectStatus}
                        noBackground={true}
                    />
                ) : null,
            enableSorting: true,
            size: 220
        },
        {
            header: t('history:arrival'),
            accessorKey: 'arrivalDate',
            cell: ({ row }) => formatDate(row.original.arrivalDate),
            enableSorting: true
        },
        {
            header: t('history:departure'),
            accessorKey: 'departureDate',
            cell: ({ row }) => formatDate(row.original.departureDate),
            enableSorting: true
        },
        {
            header: t('history:carriage_status'),
            cell: ({ row }) => (
                <HistoryCarriageStatus
                    status={row.original.status}
                    detached={row.original.detached}
                />
            ),
            enableSorting: true,
            size: 220
        }
    ];
};
