import styles from './InfoField.module.scss';

export const InfoField = ({ label, value }) => {
    return (
        <div className={styles.infoField}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value ?? '-'}</div>
        </div>
    );
};
