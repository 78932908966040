import { useMutation, useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'sam-ui-kit';

import { createModal } from 'core/modal';

import { notificateErrorResponse } from 'utils/serverErrors';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormDatePickerField } from 'components/Form/FormDatePickerField';
import { FormInputField } from 'components/Form/FormInputField';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';
import { RequiredField } from 'components/Form/rules';

import { NaturalSheetTable } from 'modules/Situations/components/SituationEditModal/components/NaturalSheetTable/NaturalSheetTable';
import { NaturalSheetUpload } from 'modules/Situations/components/SituationEditModal/components/NaturalSheetUpload';
import { useRefetchDashboard } from 'modules/Situations/hooks/useRefetchDashboard';
import {
    createSituation,
    editSituation,
    getSituationDetails
} from 'modules/Situations/services';

export const SituationEditModal = createModal(
    (props) => {
        const { t } = useTranslation();
        const refetch = useRefetchDashboard();
        const { id, prevSituationId } = props;

        const formData = useQuery({
            queryKey: ['getSituationDetails', id],
            queryFn: () => getSituationDetails(id),
            enabled: !!id
        });

        const methods = useForm({
            defaultValues: {
                departure: 'park',
                sheetWagons: {},
                wagons: [],
                prevSituationId
            }
        });
        const sheetWagons = useWatch({
            name: 'sheetWagons',
            control: methods.control
        });

        const wagons = useWatch({
            name: 'wagons',
            control: methods.control
        });
        const isDepStation = useWatch({
            name: 'isDepStation',
            control: methods.control
        });

        useEffect(() => {
            if (formData.data) {
                const data = { ...formData.data };
                if (prevSituationId) {
                    data.prevSituationId = prevSituationId;
                }
                if (formData.data.id && isNil(formData.data.isDepStation)) {
                    data.isDepStation = false;
                }

                methods.reset(data);
            }
        }, [formData.data, methods, prevSituationId]);

        const mutation = useMutation({
            mutationFn: id ? editSituation : createSituation,
            onSuccess: () => {
                refetch();
                props.onOk();
            },
            onError: (response) => notificateErrorResponse(response, t)
        });

        const handleRemoveNaturalSheet = () => {
            methods.setValue('sheetWagons', {});
            methods.setValue('wagons', []);
        };

        const handleUploadSuccess = (data) => {
            if (data.indexPoezd) {
                methods.setValue('indexPoezd', data.indexPoezd);
            }

            if (data.nomPoezd) {
                methods.setValue('nomPoezd', data.nomPoezd);
            }

            if (data.station) {
                methods.setValue('arrivalStation', data.station);
            }
        };

        return (
            <Modal
                title={id ? t('actions.edit') : t('actions.create')}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'md'}
                {...props}
            >
                <Form
                    methods={methods}
                    onSubmit={mutation.mutate}
                    disabled={mutation.isPending || formData.isLoading}
                >
                    <Modal.ModalBody size={props.isMobile && 'full'}>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormInputField
                                        label={t('situations:train_index')}
                                        name="indexPoezd"
                                        rules={RequiredField}
                                        disabled={!!id}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormInputField
                                        label={t('situations:train_number')}
                                        name="nomPoezd"
                                        rules={RequiredField}
                                        disabled={!!id}
                                    />
                                </Col>

                                <Col span={12}>
                                    <FormAutocomplete
                                        name="arrivalStation"
                                        label={t('station')}
                                        catalogName="STATIONS"
                                        useFullModel={true}
                                        rules={RequiredField}
                                    />
                                </Col>

                                <div className="divider  m-bot-10" />

                                <Col span={12}>
                                    <FormDatePickerField
                                        label={t(
                                            'situations:date_time_arrival'
                                        )}
                                        name="arrivalDate"
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormAutocomplete
                                        name="arrivalPark"
                                        label={t('situations:park')}
                                        catalogName="station_parks"
                                        useFullModel={true}
                                    />
                                </Col>
                                <Col span={props.isMobile ? 12 : 6}>
                                    <FormInputField
                                        label={t('situations:path')}
                                        name="railwayLine"
                                    />
                                </Col>
                                {!!id && (
                                    <>
                                        <div className="divider  m-bot-16" />
                                        <Col span={12}>
                                            <FormDatePickerField
                                                label={t(
                                                    'situations:date_time_departure'
                                                )}
                                                name="departureDate"
                                                rules={RequiredField}
                                            />
                                        </Col>
                                        <Col span={props.isMobile ? 12 : 6}>
                                            <FormSegmentControlField
                                                label={t(
                                                    'situations:departure'
                                                )}
                                                name="isDepStation"
                                                segmentsConfig={[
                                                    {
                                                        label: t(
                                                            'situations:park'
                                                        ),
                                                        value: false
                                                    },
                                                    {
                                                        label: t('station'),
                                                        value: true
                                                    }
                                                ]}
                                            />
                                        </Col>
                                        <Col span={props.isMobile ? 12 : 6}>
                                            {isDepStation ? (
                                                <FormAutocomplete
                                                    name="departureStation"
                                                    label={t('station')}
                                                    catalogName="STATIONS"
                                                    useFullModel={true}
                                                    rules={RequiredField}
                                                />
                                            ) : (
                                                <FormAutocomplete
                                                    name="departurePark"
                                                    label={t('situations:park')}
                                                    catalogName="station_parks"
                                                    useFullModel={true}
                                                    rules={RequiredField}
                                                />
                                            )}
                                        </Col>
                                        {isDepStation && (
                                            <Col span={12}>
                                                <FormAutocomplete
                                                    clearable
                                                    name="escortGuard"
                                                    label={t(
                                                        'situations:escort'
                                                    )}
                                                    catalogName="GUARDS"
                                                    useFullModel={true}
                                                    placeholder={t(
                                                        'applications:choose_security'
                                                    )}
                                                />
                                            </Col>
                                        )}
                                    </>
                                )}

                                <div className="divider  m-bot-16" />

                                <Col span={12}>
                                    {sheetWagons?.wagonList ? (
                                        <NaturalSheetTable
                                            wagonList={sheetWagons.wagonList}
                                            onRemove={
                                                id
                                                    ? undefined
                                                    : handleRemoveNaturalSheet
                                            }
                                        />
                                    ) : wagons.length ? (
                                        <NaturalSheetTable
                                            wagonList={wagons}
                                            onRemove={
                                                id
                                                    ? undefined
                                                    : handleRemoveNaturalSheet
                                            }
                                        />
                                    ) : (
                                        <NaturalSheetUpload
                                            name="sheetWagons"
                                            onUploadSuccess={
                                                handleUploadSuccess
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Modal.CancelButton
                                label={t('actions.cancel')}
                                block={props.isMobile}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.save')}
                                type="submit"
                                block={props.isMobile}
                                disabled={
                                    mutation.isPending || formData.isLoading
                                }
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        chainOnClose: true,
        displayName: 'SituationEditModal'
    }
);
