import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'sam-ui-kit';
import { createModal } from 'src/core/modal';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormDateIntervalPickerField } from 'components/Form/FormDateIntervalPickerField';
import { FormSelectField } from 'components/Form/FormSelectField';
import { RequiredField } from 'components/Form/rules';

import { getStatusOptions } from 'modules/Defects/utils';

export const DefectsFiltersModal = createModal(
    (props) => {
        const { t } = useTranslation();

        const userStationCode = useSelector(
            (state) => state.auth.user?.profileDataMap?.STATION_CODE
        );

        const [defaultValues, setCustomFilters] = useQueryParam(
            'customFilters',
            withDefault(CustomFilterParam, {
                all_station_code_in: userStationCode
            })
        );

        const methods = useForm({
            defaultValues: defaultValues ?? {}
        });

        const handleSubmit = (data) => {
            setCustomFilters(data);
            props.onOk();
        };

        const handleReset = () => {
            methods.reset({ all_station_code_in: null });
        };

        return (
            <Modal
                title={t('filter')}
                showConfirmOnClose={false}
                size={props.isMobile ? 'full' : 'md'}
                {...props}
            >
                <Form methods={methods} onSubmit={handleSubmit}>
                    <Modal.ModalBody>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormDateIntervalPickerField
                                        startDateName="created_at_gte"
                                        endDateName="created_at_lte"
                                        label={t('defects:defects_date')}
                                        isCleanable={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="wagon_code_eq"
                                        label={t('defects:carriage')}
                                        catalogName="WAGON_NUMBER"
                                        placeholder={t(
                                            'defects:carriage_number'
                                        )}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="defect_code_eq"
                                        label={t('defects:comment')}
                                        catalogName="WAGON_DEFECTS"
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="station_code_eq"
                                        label={t(
                                            'defects:defect_created_station'
                                        )}
                                        catalogName="STATIONS"
                                        placeholder={t(
                                            'defects:choose_station'
                                        )}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="detached_station_code_eq"
                                        label={t('defects:detached_station')}
                                        catalogName="STATIONS"
                                        placeholder={t(
                                            'defects:choose_station'
                                        )}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="all_station_code_in"
                                        label={t('station')}
                                        catalogName="STATIONS"
                                        placeholder={t(
                                            'applications:choose_station'
                                        )}
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormSelectField
                                        label={t('status')}
                                        name="status_eq"
                                        options={getStatusOptions(t)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="guard_username_eq"
                                        label={t('defects:security')}
                                        catalogName="GUARDS"
                                        placeholder={t(
                                            'defects:choose_security'
                                        )}
                                        menuPlacement="top"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Modal.CancelButton
                                label={t('actions.reset_filters')}
                                block={props.isMobile}
                                onClick={handleReset}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.accept')}
                                type="submit"
                                block={props.isMobile}
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        chainOnClose: true,
        displayName: 'DefectsFiltersModal'
    }
);
