import { userPermissionsNormalizer } from 'modules/Auth/normalizers/userPermissionsNormalizer';

export const loadAuthState = () => {
    const authState = localStorage.getItem('authState');

    if (!authState) {
        return undefined;
    }

    const authStateObj = JSON.parse(authState);

    const permissions = userPermissionsNormalizer(authStateObj.user);
    // patchMapbox(permissions.find((item) => item === 'USE_MAPBOX_PROXY'));
    return { ...authStateObj, permissions };
};

export const setLocalStorage = ({
    token,
    refreshToken,
    user,
    permissions,
    isLoggedIn
}) => {
    const authState = {
        token,
        refreshToken,
        user,
        isLoggedIn,
        permissions
    };
    localStorage.setItem('authState', JSON.stringify(authState));
};

export const resetLocalStorage = () => {
    localStorage.removeItem('authState');
};
