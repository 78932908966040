import { useCallback, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useSort = (initialState) => {
    const [queryParam, setQueryParam] = useQueryParam(
        'orders',
        initialState
            ? withDefault(StringParam, JSON.stringify(initialState))
            : undefined
    );

    const [sort, setSortInner] = useState(() =>
        initialState ? JSON.parse(queryParam) : []
    );

    /* eslint-disable react-hooks/exhaustive-deps */
    const setSort = useCallback((nextState) => {
        if (typeof nextState === 'function') {
            setSortInner((old) => {
                const newState = nextState(old);
                newState.length === 0
                    ? setQueryParam(undefined, 'replaceIn')
                    : setQueryParam(JSON.stringify(newState), 'replaceIn');
                return newState;
            });
        } else {
            setSortInner(nextState);
            nextState.length === 0
                ? setQueryParam(undefined, 'replaceIn')
                : setQueryParam(JSON.stringify(nextState), 'replaceIn');
        }
    }, []);

    return [sort, setSort];
};
