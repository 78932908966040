import styles from './Header.module.scss';
import clsx from 'clsx';

export const Header = ({ title, actions, size }) => {
    const isString = typeof title === 'string';

    return (
        <div className={styles.header}>
            {isString ? (
                <span
                    className={clsx(styles.title, size === 'md' && styles.md)}
                >
                    {title}
                </span>
            ) : (
                title
            )}
            {actions}
        </div>
    );
};
