import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { Dropdown } from 'sam-ui-kit';

import { resetLocalStorage } from 'utils/localStorageUtils';

import { authActions } from 'modules/Auth/reducers';

import { AvatarPlaceholder } from '../../assets/images/AvatarPlaceholder';
import styles from './UserMenu.module.scss';

export const UserMenu = ({ showInfo = true, userName = '', position = '' }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const matcher = useMatch('/:sections');

    return (
        <div className={styles.userMenu}>
            {showInfo && (
                <div className={styles.accountInfo}>
                    <div className={styles.userName}>{userName}</div>
                    <div className={styles.position}>{position}</div>
                </div>
            )}
            {/* <div className={styles.avatar}>SU</div> */}
            <AvatarPlaceholder />
            <div className={styles.dropdown}>
                <Dropdown
                    renderElement={({ open }) => (
                        <ChevronDown
                            size={16}
                            cursor="pointer"
                            className={clsx(
                                styles.icon,
                                open && styles.icon_active
                            )}
                            color="#FFFFFF"
                        />
                    )}
                    listOffset={20}
                    options={[
                        {
                            label: t('userMenu.administration'),
                            value: 'users'
                        },
                        {
                            label: t('userMenu.logout'),
                            value: 'logout'
                        }
                    ]}
                    value={matcher?.params?.sections}
                    onChange={(path) => {
                        if (path === 'logout') {
                            dispatch(authActions.setIsLoggedIn(false));
                            resetLocalStorage();
                            return;
                        }
                        navigate(`/administration/${path}`);
                    }}
                />
            </div>
        </div>
    );
};
