import { formatDate } from './dateUtils';

export const escapeSpecialChars = (str) => {
    return str.replace(/([\\'"])/g, '$1');
};

export const getFIOShort = (
    person,
    fioKeys = { f: 'lastName', i: 'firstName', o: 'secondName' }
) => {
    if (!person[fioKeys.f]) return '';

    let label = person[fioKeys.f];
    if (person[fioKeys.i]) {
        label = `${label} ${person[fioKeys.i][0]}.`;
    }
    if (person[fioKeys.o]) {
        label = `${label} ${person[fioKeys.o][0]}.`;
    }
    return label;
};

export const getDriverLabel = (driver = {}) => {
    if (driver.currentPersonnelNumber) {
        return `${driver.currentPersonnelNumber}${getFIOShort(driver).length ? ` - ${getFIOShort(driver)}` : ''}`;
    } else {
        return getFIOShort(driver);
    }
};

export const getFIOFull = (
    person,
    fioKeys = { f: 'lastName', i: 'firstName', o: 'middleName' }
) => {
    let label = person[fioKeys.f];
    if (person[fioKeys.i]) {
        label = `${label} ${person[fioKeys.i]}`;
    }
    if (person[fioKeys.o]) {
        label = `${label} ${person[fioKeys.o]}`;
    }
    return label;
};

export const getStationOptionLabel = (option) =>
    `${option?.startStation?.name} - ${formatDate(option?.arrivalTime)}`;

export const renderValue = (value, defaultValue) => {
    if (value || value === 0) {
        return value;
    }
    return defaultValue;
};
