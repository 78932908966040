import { SUCCESS_STATUS } from 'settings';

import { isSuccessResponse } from 'utils/http';

export const loginResponseNormalizer = (response) => {
    if (
        isSuccessResponse(response) &&
        response.data.status === SUCCESS_STATUS
    ) {
        return {
            status: response.data.status,
            token: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            user: response.data.user
        };
    }

    return {
        token: '',
        refreshToken: '',
        user: null,
        status: response?.data?.status
    };
};
