import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSize } from 'hooks/useSize';

import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';

import { SituationCardList } from 'modules/Situations/components/SituationCardList/SituationCardList';
import { SituationsPostHeader } from 'modules/Situations/components/SituationsPostHeader/SituationsPostHeader';
import { SituationsPostTable } from 'modules/Situations/components/SituationsPostTable';

import styles from './SituationsPost.module.scss';

export const SituationsPost = ({ situationPosts, activePost }) => {
    const { t } = useTranslation();
    const ref = useRef();
    const { showSmall } = useSize(ref);

    const getDisplayedPosts = () =>
        activePost === 'all'
            ? situationPosts.data
            : situationPosts.data.filter((post) => {
                  if (activePost === 'not-defined' && !post.arrivalPark) {
                      return true;
                  }
                  return post.arrivalPark?.code === activePost;
              });

    return (
        <div ref={ref} className={styles.situationsPost}>
            {situationPosts.isLoading ? (
                <SkeletonLoader width="100%" height={294}>
                    <rect width="100%" height={140} rx={15} ry={15} />
                    <rect y={154} width="100%" height={140} rx={15} ry={15} />
                </SkeletonLoader>
            ) : (
                getDisplayedPosts().map((post) => (
                    <div key={post.arrivalPark?.id}>
                        <SituationsPostHeader
                            title={post.arrivalPark?.nameRu ?? t('not_defined')}
                            dataLength={post.situations.length}
                        />
                        {!showSmall ? (
                            <SituationsPostTable
                                data={post.situations}
                                refetch={situationPosts.refetch}
                            />
                        ) : (
                            <SituationCardList data={post.situations} />
                        )}
                    </div>
                ))
            )}
        </div>
    );
};
