import clsx from 'clsx';

import styles from './TextButton.module.scss';

export const TextButton = ({
    label,
    color,
    onClick,
    disabled,
    noUnderline = false
}) => {
    return (
        <button
            type="button"
            className={clsx(styles.textButton, styles[color], {
                [styles.noUnderline]: noUnderline
            })}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    );
};
