import { useState } from 'react';

export const useDropdown = () => {
    const [isVisible, setVisibility] = useState(false);

    const dropdownButtonClickHandler = () => {
        setVisibility((prevState) => !prevState);
    };

    const dropdownListItemClickHandler = () => {
        setVisibility(false);
    };

    return {
        isVisible,
        dropdownButtonClickHandler,
        dropdownListItemClickHandler
    };
};
