import useResizeObserver from '@react-hook/resize-observer';
import { useEffect, useLayoutEffect, useState } from 'react';

const LARGE_BREAKPOINT = 1440;
const MIDDLE_BREAKPOINT = 768;

/**
 * @deprecated use useScreenSize instead
 */
export const useSize = (target) => {
    const [size, setSize] = useState();
    const [showMiddle, setShowMiddle] = useState();
    const [showSmall, setShowSmall] = useState();

    useLayoutEffect(() => {
        setSize(target?.current?.getBoundingClientRect());
    }, [target]);

    useEffect(() => {
        if (size) {
            const { width } = size;
            if (width < MIDDLE_BREAKPOINT) {
                setShowMiddle(false);
                setShowSmall(true);
            } else if (width < LARGE_BREAKPOINT) {
                setShowMiddle(true);
                setShowSmall(false);
            } else {
                setShowMiddle(false);
                setShowSmall(false);
            }
        }
    }, [size, target]);

    useResizeObserver(target, (entry) => setSize(entry.contentRect));

    return {
        showMiddle,
        showSmall
    };
};
