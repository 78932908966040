import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import { uploadFile } from 'core/services/file';

export const useFieldError = (fieldErrors, setError) => {
    useEffect(() => {
        if (fieldErrors !== undefined) {
            fieldErrors.forEach((fieldError) => {
                const error = {};
                if (fieldError.error) {
                    error.message = fieldError.error;
                }
                setError(fieldError.fieldName, error);
            });
        }
    }, [fieldErrors, setError]);
};

export const useUploadImage = ({ onSuccess, onError }) => {
    return useMutation({
        mutationFn: (data) => {
            return uploadFile(data.avatarImage);
        },
        mutationKey: ['uploadFile'],
        onSuccess: onSuccess,
        onError: onError
    });
};
