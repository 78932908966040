import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';

import styles from './NaturalSheetTable.module.scss';

export const NaturalSheetTable = ({ wagonList, onRemove }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>Натурный лист</div>
                {onRemove && (
                    <div className={styles.actions} onClick={onRemove}>
                        {t('actions.delete')}
                    </div>
                )}
            </div>
            <Table
                loading={false}
                data={wagonList}
                columns={getColumnsConfig(t)}
            />
        </div>
    );
};

function getColumnsConfig(t) {
    return [
        {
            header: 'НП',
            accessorKey: 'wagonOrder',
            minSize: 60,
            size: 60
        },
        {
            header: t('carriage'),
            cell: ({ row }) => <span>{row.original.wagonCode}</span>
        },
        {
            header: 'СТНЗ',
            cell: ({ row }) => <span>{row.original.stanNazn}</span>
        },
        {
            header: t('cargo'),
            cell: ({ row }) => <span>{row.original.cargoCode.code}</span>
        }
    ];
}
