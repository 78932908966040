import { FILE_URL } from 'settings';

import api from 'core/api';
import { DefaultNormalizer } from 'core/normalizers/DataRequest';

export const uploadFile = (file) => {
    const request = new FormData();
    request.append('file', file, file.name);
    return api.post(FILE_URL, request, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        normalizer: DefaultNormalizer
    });
};

export const getFile = (fileId) => {
    return api.get(FILE_URL + '/' + fileId, {
        responseType: 'blob'
    });
};

// export const getFilePath = (fileId) => FILE_URL + '/' + fileId + '/public/';
export const getFilePath = (fileId) => FILE_URL + '/public/' + fileId;
