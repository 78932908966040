import { noop } from 'lodash';
import { Filter, PlusCircle } from 'lucide-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'sam-ui-kit';

import { showModal } from 'core/modal';

import { useSize } from 'hooks/useSize';

import { Header } from 'components/Header/Header';

import { SituationEditModal } from 'modules/Situations/components/SituationEditModal/SituationEditModal';
import { SituationsFiltersModal } from 'modules/Situations/components/SituationsFiltersModal/SituationsFiltersModal';

import styles from './SituationsHeader.module.scss';

export const SituationsHeader = () => {
    const ref = useRef();
    const { t } = useTranslation();
    const { showSmall } = useSize(ref);
    const handleCreate = () => {
        showModal(SituationEditModal).then(noop, noop);
    };

    const handleFiltersClick = () => {
        showModal(SituationsFiltersModal).then(noop, noop);
    };

    return (
        <div className={styles.header} ref={ref}>
            <Header
                title={
                    showSmall
                        ? t('situations:my_tasks')
                        : t('situations:entries')
                }
                actions={
                    <Space gap={8}>
                        <Button
                            label={t('actions.filter')}
                            variant="default"
                            size="md"
                            icon={<Filter size={16} />}
                            onClick={handleFiltersClick}
                        />
                        {!showSmall && (
                            <Button
                                label={t('actions.create')}
                                onClick={handleCreate}
                                variant="primary"
                                icon={<PlusCircle size={16} />}
                            />
                        )}
                    </Space>
                }
            />
        </div>
    );
};
