import clsx from 'clsx';
import {
    Bell,
    ClipboardList,
    History,
    LayoutDashboard,
    PackageX
} from 'lucide-react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AvatarPlaceholder } from 'src/assets/images/AvatarPlaceholder';
import { useDropdown } from 'src/hooks/useDropdown';

import { useScreenSize } from 'hooks/useScreenSize';

import { getFIOShort } from 'utils/textUtils';

import { LanguageSelect } from 'components/LanguageSelect/LanguageSelect';
import { MainMenu } from 'components/MainMenu/MainMenu';
import { MobileMainMenu } from 'components/MobileMainMenu/MobileMainMenu';
import { StationInfo } from 'components/StationInfo/StationInfo';
import { UserMenu } from 'components/UserMenu/UserMenu';

import styles from './Header.module.scss';

const menuItems = [
    {
        url: '/',
        module: 'situations',
        icon: <LayoutDashboard size={20} />
    },
    {
        url: '/applications',
        module: 'applications',
        icon: <ClipboardList size={20} />
    },
    {
        url: '/defects',
        module: 'defects',
        icon: <PackageX size={20} />
    },
    { url: '/history', module: 'history', icon: <History size={20} /> }
];

const headerLanguageOptions = [
    { title: 'rus', value: 'RUS' },
    { title: 'kz', value: 'KZ' }
];

const menuLanguageOptions = [
    { title: 'russian', value: 'RUS' },
    { title: 'kazakh', value: 'KZ' }
];

export const Header = () => {
    const navRef = useRef(null);
    const { isMobile, size } = useScreenSize();
    const [selectedLanguage, setSelectedLanguage] = useState('RUS');
    const user = useSelector((state) => state.auth.user);
    const mobileMenuRef = useRef(null);
    const {
        isVisible: mobileMenuIsVisible,
        dropdownButtonClickHandler: mobileMenuButtonClickHandler,
        dropdownListItemClickHandler: mobileMenuItemClickHandler
    } = useDropdown(mobileMenuRef);

    return (
        <header ref={navRef} className={styles.header}>
            <div
                className={clsx(
                    styles.wrapper,
                    mobileMenuIsVisible && styles.active
                )}
                id="header"
            >
                {isMobile ? (
                    <i
                        onClick={() =>
                            mobileMenuButtonClickHandler(mobileMenuRef)
                        }
                    >
                        <span>
                            <span />
                        </span>
                    </i>
                ) : (
                    <div className={styles.logo}>
                        {size === 'DESKTOP_XL' && (
                            <img
                                alt="qorgau"
                                src={
                                    process.env.PUBLIC_URL + '/logo-header.png'
                                }
                            />
                        )}
                        {size === 'DESKTOP' && (
                            <img
                                alt="qorgau"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/logo-header-short.png'
                                }
                            />
                        )}
                    </div>
                )}

                {!isMobile && (
                    <div className={styles.wrapper}>
                        <div className={styles.divider} />
                        <StationInfo />
                    </div>
                )}
                <div className={styles.userInfo}>
                    <div className={styles.language}>
                        <LanguageSelect
                            options={headerLanguageOptions}
                            selected={selectedLanguage}
                            onChange={setSelectedLanguage}
                        />
                    </div>
                    <div className={styles.notification}>
                        <Bell color="#FFFFFF" size={20} />
                    </div>
                    <UserMenu
                        showInfo={size === 'DESKTOP_XL'}
                        userName={getFIOShort(user)}
                        position={user.profileDataMap.POSITION_NAME}
                    />
                </div>
            </div>
            {!isMobile && (
                <div className={styles.navigation} id="navigation">
                    <MainMenu menuItems={menuItems} />
                </div>
            )}

            {mobileMenuIsVisible && isMobile && (
                <div ref={mobileMenuRef} className={styles.mobile}>
                    <MobileMainMenu
                        menuItems={menuItems}
                        onClick={mobileMenuItemClickHandler}
                    />
                    <StationInfo />
                    <div className={styles.mobileWrapper}>
                        <div className={styles.user}>
                            <AvatarPlaceholder size={32} />
                            <div className={styles.info}>
                                <div className={styles.userName}>
                                    {getFIOShort(user)}
                                </div>
                                <div className={styles.position}>
                                    {user.profileDataMap.POSITION_NAME}
                                </div>
                            </div>
                        </div>
                        <div className={styles.language}>
                            <LanguageSelect
                                options={menuLanguageOptions}
                                selected={selectedLanguage}
                                onChange={setSelectedLanguage}
                            />
                        </div>
                    </div>
                    <div className={styles.logo}>
                        <img
                            alt="qorgau"
                            src={process.env.PUBLIC_URL + '/logo-header.png'}
                        />
                    </div>
                </div>
            )}
        </header>
    );
};
