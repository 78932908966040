import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { Card } from 'components/Card/Card';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormSelectField } from 'components/Form/FormSelectField';
import { FormTextareaField } from 'components/Form/FormTextareaField';
import { RequiredField } from 'components/Form/rules';

import { getStatusOptions } from 'modules/Applications/utils';

import { CarriageAttributes } from '../CarriageAttributes/CarriageAttributes';
import styles from './Carriage.module.scss';

export const Carriage = ({ carriageFields, isFetching }) => {
    const { fields, remove, append } = carriageFields;
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    return (
        <Row gutter={[16, 16]}>
            <Col span={3} md={12} sm={12}>
                <div className={styles.card}>
                    <Card
                        title={t('applications:carriage')}
                        hideActions={true}
                        size="md"
                    >
                        <FormAutocomplete
                            clearable
                            name="wagonCode"
                            label={t('applications:carriage')}
                            rules={RequiredField}
                            catalogName="WAGON_NUMBER_AVAILABLE"
                        />
                        <FormAutocomplete
                            clearable
                            name="defect"
                            label={t('applications:comment')}
                            catalogName="WAGON_DEFECTS"
                            rules={RequiredField}
                            useFullModel={true}
                        />
                        <FormTextareaField label={t('note')} name="remark" />
                        <FormSelectField
                            label={t('status')}
                            name="initialStatus"
                            options={getStatusOptions(t)}
                            rules={RequiredField}
                        />
                        <Button
                            label={t('applications:delete_carriage')}
                            variant="secondary"
                            disabled={isFetching}
                        />
                    </Card>
                    {!isMobile && (
                        <Button
                            label={t('actions.save_and_exit')}
                            variant="primary"
                            block={true}
                            type="submit"
                            disabled={isFetching}
                            size="lg"
                        />
                    )}
                </div>
            </Col>
            <Col span={9} md={12} sm={12}>
                <CarriageAttributes
                    fields={fields}
                    onDelete={remove}
                    onAppend={append}
                />
            </Col>
            {isMobile && (
                <Col>
                    <Button
                        label={t('actions.save_and_exit')}
                        variant="primary"
                        block={true}
                        size="lg"
                        type="submit"
                        disabled={isFetching}
                    />
                </Col>
            )}
        </Row>
    );
};
