import axios from 'axios';
import { AUTH_API_URL, ENTITLEMENT_URL } from 'settings';

const login = (username, password) => {
    return axios.post(AUTH_API_URL, {
        username,
        password,
        appName: 'QORGAU_APP'
    });
};

const changePassword = (params) => {
    return axios.put(
        ENTITLEMENT_URL +
            '/users/change-password/' +
            params.username.toUpperCase(),
        {
            currentPassword: params.password,
            newPassword: params.newPassword,
            confirmationPassword: params.confirmationPassword
        }
    );
};

export const Services = {
    login,
    changePassword
};
