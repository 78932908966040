import { useTranslation } from 'react-i18next';

import { StatusText } from 'components/StatusText/StatusText';

export const HistoryStatus = ({ status }) => {
    const { t } = useTranslation();
    switch (status) {
        case 'ACCEPTED_WITHOUT_DEFECT':
            return (
                <StatusText
                    label={t('history:status.ACCEPTED_WITHOUT_DEFECT')}
                    variant="green"
                />
            );
        case 'ACCEPTED_WITH_DEFECT':
            return (
                <StatusText
                    label={t('history:status.ACCEPTED_WITH_DEFECT')}
                    variant="orange"
                />
            );
        case 'ACCEPTED_WITH_GFA':
            return (
                <StatusText
                    label={t('history:status.ACCEPTED_WITH_GFA')}
                    variant="orange"
                />
            );
        case 'REJECTED':
            return (
                <StatusText
                    label={t('history:status.REJECTED')}
                    variant="red"
                />
            );
        default:
            return <StatusText label={''} variant="" />;
    }
};
