import { useTranslation } from 'react-i18next';

import styles from './SituationsPostHeader.module.scss';

export const SituationsPostHeader = ({ title, dataLength }) => {
    const { t } = useTranslation();

    return (
        <h1 className={styles.situationsPostHeader}>
            <span>
                <span className={styles.greyText}>
                    {t('situations:post')}:{' '}
                </span>
                {title}
                <span className={styles.count}>
                    ({dataLength ?? t('no_rows')})
                </span>
            </span>
        </h1>
    );
};
