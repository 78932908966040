import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import api from 'core/api';
import { DataRequestDetailsNormalizer } from 'core/normalizers/DataRequest';

export const useAutocompleteValue = ({
    catalog,
    fieldValue,
    fieldName,
    isDisabled
}) => {
    const [valuesMap, setValuesMap] = useState({});
    const updateValuesMap = useCallback(
        (newValue) => {
            if (newValue) {
                if (Array.isArray(newValue)) {
                    setValuesMap((valueMap) => ({
                        ...valueMap,
                        ...processArrayValue(newValue, fieldName)
                    }));
                } else {
                    setValuesMap((valueMap) => ({
                        ...valueMap,
                        [newValue[fieldName]]: newValue
                    }));
                }
            }
        },
        [fieldName]
    );

    const { isFetching, data } = useQuery({
        queryKey: [catalog.url, fieldValue],
        queryFn: () =>
            api.get(catalog.url, {
                params: getRequestParams(catalog, fieldName, fieldValue),
                normalizer: DataRequestDetailsNormalizer
            }),
        onSuccess: updateValuesMap,
        enabled: !isDisabled && isValuesNeedToFetch(fieldValue, valuesMap)
    });

    useEffect(() => {
        if (data?.length) {
            updateValuesMap(data);
        }
    }, [data, updateValuesMap]);

    return {
        isFetchingValue: isFetching,
        valuesMap,
        updateValuesMap
    };
};

const parseValue = (fieldValue) =>
    typeof fieldValue === 'string' ? fieldValue.split(',') : [fieldValue];

const isValuesNeedToFetch = (values, valuesMap) => {
    if (!values) {
        return false;
    }

    const parsedValues = parseValue(values);

    for (let i = 0; i < parsedValues.length; i++) {
        if (!valuesMap[parsedValues[i]]) {
            return true;
        }
    }
    return false;
};

const processArrayValue = (value, fieldName) => {
    const nextValues = {};
    value.forEach((item) => {
        nextValues[item[fieldName]] = item;
    });
    return nextValues;
};

const getRequestParams = (catalog, fieldName, fieldValue) => {
    let params;
    const values = parseValue(fieldValue);
    if (values.length > 1) {
        params = { [`${catalog.findName}_eq`]: fieldValue };
    } else {
        params = {
            [`${catalog.findName}_eq`]:
                typeof fieldValue === 'object'
                    ? fieldValue[fieldName]
                    : fieldValue
        };
    }

    return params;
};
