import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useRefetchDashboard = () => {
    const queryClient = useQueryClient();

    return useCallback(() => {
        queryClient.refetchQueries({
            queryKey: ['situationsList']
        });
        queryClient.refetchQueries({
            queryKey: ['cargoEscortList']
        });
        queryClient.refetchQueries({
            queryKey: ['cargoOncomingList']
        });
    }, [queryClient]);
};
