import { useParams } from 'react-router-dom';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';
import { useDataRequest } from 'core/queries/useDataRequest';

import { useSort } from 'hooks/useSort';

import { HistorySituationsHeader } from 'modules/History/components/HistorySituationsHeader/HistorySituationsHeader';
import { HistorySituationsTable } from 'modules/History/components/HistorySituationsTable/HistorySituationsTable';
import { historySituationsInitialSort } from 'modules/History/consts';
import { getHistorySituations } from 'modules/History/services';

export const HistoryCarriageSituations = () => {
    const { appWagonId } = useParams();

    const [customFilters] = useQueryParam(
        'customFilters',
        withDefault(CustomFilterParam, {
            app_wagon_id_eq: appWagonId
        })
    );

    const [sort, setSort] = useSort(historySituationsInitialSort);

    const historySituations = useDataRequest(
        'historySituations',
        getHistorySituations,
        {
            customFilters,
            sort
        },
        null,
        !!appWagonId
    );

    return (
        <>
            <HistorySituationsHeader />
            <HistorySituationsTable
                sort={sort}
                setSort={setSort}
                isLoading={historySituations.isLoading}
                data={historySituations.data}
            />
        </>
    );
};
