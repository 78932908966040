import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldControl } from 'sam-ui-kit';

import { AutocompleteField } from 'components/AutocompleteField/AutocompleteField';
import { FormStatusContext } from 'components/Form/Form';

export const FormAutocomplete = ({
    rules,
    disabled,
    noHintSpace,
    onAfterChange,
    disableHint,
    ...otherProps
}) => {
    const methods = useFormContext();
    const formStatus = useContext(FormStatusContext);
    const { t } = useTranslation();

    const { field, fieldState } = useController({
        name: otherProps.name,
        control: methods.control,
        rules
    });

    return (
        <FieldControl
            label={otherProps.label}
            hint={
                disableHint
                    ? null
                    : (fieldState.error && t(fieldState.error?.message)) ||
                      otherProps.hint
            }
            required={rules?.required.value}
            error={fieldState.invalid}
            noHintSpace={noHintSpace}
        >
            <AutocompleteField
                {...otherProps}
                disabled={disabled || formStatus.disabled}
                value={field.value}
                onChange={(value, fullModel = null) => {
                    field.onChange({
                        target: {
                            value: otherProps.useFullModel ? fullModel : value,
                            name: otherProps.name
                        }
                    });
                    onAfterChange?.(otherProps.name, value, fullModel);
                }}
                error={fieldState.invalid}
            />
        </FieldControl>
    );
};
