import { createAsyncThunk } from '@reduxjs/toolkit';

import { loginResponseNormalizer } from './normalizers/loginResponseNormalizer';
import { Services } from './services';

export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            const response = await Services.login(username, password);

            return loginResponseNormalizer(response);
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (params, { rejectWithValue }) => {
        try {
            const response = await Services.changePassword(params);
            return loginResponseNormalizer(response);
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    }
);
