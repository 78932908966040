import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'sam-ui-kit';
import { createModal } from 'src/core/modal';

import { queryClient } from 'core/queryClient';

import { notificateErrorResponse } from 'utils/serverErrors';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormDatePickerField } from 'components/Form/FormDatePickerField';
import { FormInputField } from 'components/Form/FormInputField';
import { FormSelectField } from 'components/Form/FormSelectField';
import { FormTextareaField } from 'components/Form/FormTextareaField';
import { RequiredField } from 'components/Form/rules';

import { getDefectDetails, updateDefect } from 'modules/Defects/services';
import { getStatusOptions } from 'modules/Defects/utils';

export const DefectsEditModal = createModal(
    (props) => {
        const { t } = useTranslation();
        const { id } = props;

        const methods = useForm({
            defaultValues: {
                wagonCode: ''
            }
        });

        const formData = useQuery({
            queryKey: ['getDefectDetails', id],
            queryFn: () => getDefectDetails(id),
            onSuccess: (response) => {
                console.log('response', response);
            },
            enabled: !!id
        });

        useEffect(() => {
            if (formData.data) {
                methods.reset(formData.data);
            }
        }, [formData.data, methods]);

        const mutation = useMutation({
            mutationFn: (data) =>
                updateDefect(id, {
                    docNumber: data.docNumber,
                    status: data.status,
                    note: data.note
                }),
            onSuccess: () => {
                queryClient.refetchQueries({
                    queryKey: ['defectsList']
                });
                props.onOk();
            },
            onError: (response) => notificateErrorResponse(response, t)
        });

        return (
            <Modal
                title={t('defects:defects_edit')}
                showConfirmOnClose={false}
                size={'md'}
                {...props}
            >
                <Form
                    methods={methods}
                    onSubmit={mutation.mutate}
                    disabled={mutation.isPending || formData.isLoading}
                >
                    <Modal.ModalBody>
                        <div className="modalPadding">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormInputField
                                        label={t('applications:carriage')}
                                        name="wagonCode"
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        name="park"
                                        label={t('situations:park')}
                                        catalogName="station_parks"
                                        useFullModel={true}
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormDatePickerField
                                        label={t('date')}
                                        name="createdAt"
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormAutocomplete
                                        clearable
                                        name="guardUsername"
                                        label={t('applications:security')}
                                        catalogName="GUARDS"
                                        placeholder={t(
                                            'applications:choose_security'
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormTextareaField
                                        label={t('description')}
                                        name="note"
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormSelectField
                                        label={t('status')}
                                        name="status"
                                        options={getStatusOptions(t)}
                                        rules={RequiredField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormInputField
                                        label="№"
                                        name="docNumber"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.ModalBody>
                    <Modal.ModalFooter>
                        <div className="modalPadding modalFooter">
                            <Modal.CancelButton
                                label={t('actions.cancel')}
                                block={props.isMobile}
                            />
                            <Button
                                variant="primary"
                                label={t('actions.save')}
                                type="submit"
                                block={props.isMobile}
                            />
                        </div>
                    </Modal.ModalFooter>
                </Form>
            </Modal>
        );
    },
    {
        chainOnClose: true,
        displayName: 'DefectsEditModal'
    }
);
