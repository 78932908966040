import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './HistoryCarriageStatus.module.scss';

export const HistoryCarriageStatus = ({ detached, status }) => {
    const { t } = useTranslation();

    if (!detached && status === undefined) return null;

    return (
        <span
            className={clsx(styles.label, {
                [styles['label_detached']]: detached,
                [styles['label_notAccepted']]: !status && !detached
            })}
        >
            {detached
                ? t('history:detached')
                : status
                  ? t('history:accepted')
                  : t('history:not_accepted')}
        </span>
    );
};
