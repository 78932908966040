import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useUserStation } from 'hooks/useUserStation';

import { getCargoCount } from 'components/StationInfo/services';

import styles from './StationInfo.module.scss';

const select = (data) => {
    return data[0];
};
export const StationInfo = () => {
    const { t } = useTranslation();

    const userStation = useUserStation();

    const { data } = useQuery({
        queryKey: ['cargoCount'],
        queryFn: () => getCargoCount(userStation.data.code),
        initialData: [{}],
        select,
        refetchInterval: 15000
    });

    return (
        <div className={styles.stationInfo}>
            <div className={styles.statistic}>
                <span className={styles.label}>
                    {`${t('active_defects')}: `}
                </span>
                <span className={styles.value}>
                    {data.activeDefectsCount ?? '-'}
                </span>
            </div>
            <div className={styles.statistic}>
                <span className={styles.label}>
                    {`${t('active_applications')}: `}
                </span>
                <span className={styles.value}>
                    {data.activeAppsCount ?? '-'}
                </span>
            </div>

            <div className={styles.statistic}>
                <span className={styles.label}>{`${t('station')}: `}</span>
                <span className={styles.value}>{userStation.data.name}</span>
            </div>
        </div>
    );
};
