import api from 'core/api';
import { DataRequestDetailsNormalizer } from 'core/normalizers/DataRequest';

export const getHistoryApps = (params) => {
    return api.get('/qorgau-data/api/data-request/non-paged/history_apps', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getHistorySituations = (params) => {
    return api.get(
        '/qorgau-data/api/data-request/non-paged/history_situations',
        {
            params,
            normalizer: DataRequestDetailsNormalizer
        }
    );
};
