import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'sam-ui-kit';

import { createModal } from 'core/modal';

export const ConfirmModal = createModal(
    (props) => {
        const { t } = useTranslation();

        return (
            <Modal
                title={props.title}
                {...props}
                size={props.isMobile ? 'full' : 'md'}
            >
                <Modal.ModalBody size={props.isMobile && 'full'}>
                    <div className="modalPadding">{props.description}</div>
                </Modal.ModalBody>
                <Modal.ModalFooter>
                    <div className="modalPadding modalFooter">
                        <Modal.CancelButton
                            label={t('actions.cancel')}
                            block={props.isMobile}
                        />
                        <Button
                            variant="secondary"
                            label={t('actions.ok')}
                            onClick={props.onOk}
                            block={props.isMobile}
                        />
                    </div>
                </Modal.ModalFooter>
            </Modal>
        );
    },
    {
        chainOnClose: true
    }
);
