import { useTranslation } from 'react-i18next';

import { StatusText } from 'components/StatusText/StatusText';

export const ApplicationCarriageStatus = ({ status }) => {
    const { t } = useTranslation();
    switch (status) {
        case 'ACCEPTED':
            return (
                <StatusText
                    label={t('history:status_carriage.ACCEPTED')}
                    variant="green"
                />
            );
        case 'DETACHED':
            return (
                <StatusText
                    label={t('history:status_carriage.DETACHED')}
                    variant="red"
                />
            );
        default:
            return <StatusText label={''} variant="" />;
    }
};
