import { createContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useFieldError } from 'components/Form/hooks';

export const FormStatusContext = createContext({ disabled: false });

export const Form = (props) => {
    const { methods, children, disabled, ...rest } = props;

    const statusContext = useMemo(
        () => ({
            disabled
        }),
        [disabled]
    );

    if (methods) {
        return (
            <FormStatusContext.Provider value={statusContext}>
                <UncontrolledForm methods={methods} {...rest}>
                    {children}
                </UncontrolledForm>
            </FormStatusContext.Provider>
        );
    } else {
        return (
            <FormStatusContext.Provider value={{ disabled }}>
                <ControlledForm {...rest}>{children}</ControlledForm>
            </FormStatusContext.Provider>
        );
    }
};

const UncontrolledForm = ({
    methods,
    className,
    onSubmit,
    fieldErrors,
    children
}) => {
    useFieldError(fieldErrors, methods.setError);

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                {children}
            </form>
        </FormProvider>
    );
};

const ControlledForm = ({
    children,
    onSubmit,
    className,
    fieldErrors,
    defaultValues
}) => {
    const methods = useForm({
        defaultValues,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });

    useFieldError(fieldErrors, methods.setError);

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={methods.handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                {children}
            </form>
        </FormProvider>
    );
};
