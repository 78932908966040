import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { registerModal } from 'core/modal';

import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';

export const AppModals = () => {
    const { t } = useTranslation();

    useEffect(() => {
        // регистрация common модалок
        registerModal('confirm-delete', ConfirmModal, {
            title: t('deleteConfirm.title'),
            description: t('deleteConfirm.description')
        });
    }, [t]);

    return null;
};
