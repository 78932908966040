import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import styles from './TextLink.module.scss';

export const TextLink = ({ label, url, variant = '' }) => {
    return (
        <NavLink
            to={url}
            className={clsx(
                styles.link,
                variant === 'orange' && styles['link_orange']
            )}
        >
            {label}
        </NavLink>
    );
};
