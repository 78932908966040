import { notifications } from 'sam-ui-kit';

import i18n from '../i18n';

const config = {
    'backend.locomotive.intervalOverlap': {
        label: 'backend.locomotive.intervalOverlapWithLocomotives',
        key: 'locomotives'
    }
};

export const getErrors = (responseError) => {
    let errors = [];
    const errorMessage = responseError.message;

    if (errorMessage.toUpperCase() !== 'CANCELED') {
        const responseErrors = responseError?.response?.data?.errors || [];

        if (responseErrors.length) {
            responseErrors.forEach((error) => {
                let fields = [];
                let params = '';
                if (error?.objectIds && Array.isArray(error.objectIds)) {
                    fields = [...error.objectIds];
                }
                if (
                    config[error.message] &&
                    error.params[config[error.message].key]
                ) {
                    params = error.params[config[error.message].key].reduce(
                        (acc, item, index) => `${acc} \n${index + 1}: ${item}`,
                        ''
                    );
                }

                errors.push({
                    code: error.code,
                    errorType: error.errorType,
                    fields,
                    message: config[error.message]
                        ? `${i18n.t(
                              'backendValidation.' + config[error.message].label
                          )} ${params}`
                        : i18n.t(
                              error.errorMessage ??
                                  'backendValidation.' + error.message
                          ),
                    validateMessage: config[error.message]
                        ? i18n.t(
                              error.errorMessage ??
                                  'backendValidation.' + error.message
                          )
                        : null
                });
            });
        } else {
            errors.push({
                code: responseError.response.status,
                errorType: 'ERROR',
                fields: [],
                message: responseError?.response?.data?.message || errorMessage
            });
        }
    }

    return errors;
};

export const notificateErrorResponse = (response, t) => {
    const errors = getErrors(response);
    errors.forEach(({ message }) => {
        notifications.push({
            type: 'error',
            title: t('error'),
            description: t([
                `backendValidation:${message}`,
                'backendValidation:fallbackValidationError'
            ])
        });
    });
};
