import { ArrowLeftCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Header } from 'components/Header/Header';

import { ApplicationStatistics } from 'modules/History/components/ApplicationStatistics/ApplicationStatistics';

import styles from './HistorySituationsHeader.module.scss';

export const HistorySituationsHeader = ({ historyApplication }) => {
    const navigate = useNavigate();

    const { appWagonId } = useParams();
    const { t } = useTranslation();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className={styles.header}>
            <div className={styles.pageTitle}>
                <ArrowLeftCircle
                    color="#888888"
                    size={26}
                    onClick={handleBack}
                />
                <Header
                    title={`${t('history:carriage_situations')} №${appWagonId}`}
                />
            </div>
            <ApplicationStatistics />
        </div>
    );
};
