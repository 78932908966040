const subscriptions = {};

export const eventBus = {
    /**
     * Подписка на сообщение. Обычно используется в useEffect, при маунте компонента.
     *
     * @param eventName Название сообщения на которое происходит подписка.
     *                  Все сообщения необходимо указать в eventNames.js
     * @param callback Колбек, вызывается при каждом получении сообщения.
     *
     * @returns {function} Функция для отмены подписки, вызывается в return useEffect.
     */
    subscribe : (eventName, callback) => {
        if (!subscriptions[eventName]) {
            subscriptions[eventName] = new Set();
        }

        const callbacks = subscriptions[eventName];
        callbacks.add(callback);

        return () => {
            callbacks.delete(callback);
            if (callbacks.size === 0) {
                delete subscriptions[eventName];
            }
        }
    },

    /**
     * Отправка сообщения. Все подписчиски сообщения вызовут свои колбеки.
     *
     * @param eventName Название сообщения на которое происходит подписка.
     *                  Все сообщения необходимо указать в eventNames.js
     * @param args Аргументы для колбек функции подписчиков.
     */
    emit: (eventName, ...args) => {
        if (!subscriptions[eventName]) {
            subscriptions[eventName] = new Set();
        }

        const callbacks = subscriptions[eventName];

        for (const callback of callbacks) {
            callback(...args);
        }
    }
};
