import api from 'core/api';
import { DataRequestDetailsNormalizer } from 'core/normalizers/DataRequest';

export const getCargoCount = (stationId) => {
    return api.get(
        `/qorgau-data/api/data-request/cargo_total_count?station_code_in=${stationId}`,
        {
            normalizer: DataRequestDetailsNormalizer
        }
    );
};
