import { useTranslation } from 'react-i18next';
import { Col, Row } from 'sam-ui-kit';

import { InfoField } from 'components/InfoField/InfoField';

import styles from './TwistAttributesItemView.module.scss';

export const TwistAttributesItemView = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.twist}>
            <div className={styles.header}>
                <span className={styles.title}>{t('applications:twist')}</span>
            </div>
            <Row gutter={[12, 12]}>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:attribute')}
                        value={t(
                            `applications:${data.isProductionTwist ? 'production' : 'manual'}`
                        )}
                    />
                </Col>

                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:twist_count')}
                        value={data.coilsNumber}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:color')}
                        value={data.twistColor?.nameRu}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:location')}
                        value={data.location?.nameRu}
                    />
                </Col>
                <Col span={3} sm={6}>
                    <InfoField
                        label={t('applications:remark')}
                        value={data.remark}
                    />
                </Col>
            </Row>
        </div>
    );
};
