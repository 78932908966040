// import { FallbackPage } from 'components/FallbackPage/FallbackPage';
// import { NotFoundIcon } from 'components/icons/NotFoundIcon';

export const NotFoundPage = () => (
    <div>not forund page</div>
    // <FallbackPage
    //     icon={<NotFoundIcon />}
    //     title="error"
    //     subTitle="not_found_sub_title"
    // />
);
