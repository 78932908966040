import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spin, Tabs } from 'sam-ui-kit';

import { CarriageContainerView } from 'modules/Applications/components/CarriageContanierView/CarriageContanierView';
import { CarriageView } from 'modules/Applications/components/CarriageView/CarriageView';
import { getCargoAppWalogList } from 'modules/Applications/services/services';
import { WagonDetailsPageHeader } from 'modules/Situations/components/WagonDetailsPageHeader/WagonDetailsPageHeader';

export const WagonDetailsPage = () => {
    const { wagonId, id } = useParams();
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(DEFAULT_TABS[0]);

    const { data, isFetching } = useQuery({
        queryKey: ['getCargoAppWalogListItem', wagonId],
        queryFn: () => getCargoAppWalogList({ id_eq: wagonId }),
        initialData: [{ containers: [] }],
        select
    });

    const handleSelect = (e) => {
        setSelectedTab(data.tabs.find((tab) => tab.value === e));
    };

    return (
        <div className="page-content flex-column gap-24">
            <WagonDetailsPageHeader wagonCode={data.wagon.wagonCode} />
            <Tabs
                tabs={getTabs(data.tabs, t)}
                value={selectedTab.value}
                onChange={handleSelect}
            />
            <Spin spinning={isFetching} text={t('loading')}>
                {selectedTab.type === CARRIAGE_TYPE && (
                    <CarriageView data={data.wagon} situationId={id} />
                )}
                {selectedTab.type === BOX_TYPE && (
                    <CarriageContainerView
                        data={data.wagon.containers[selectedTab.value - 1]}
                        containerIndex={selectedTab.value - 1}
                        situationId={id}
                    />
                )}
            </Spin>
        </div>
    );
};
const CARRIAGE_TYPE = 'CARRIAGE_TYPE';
const BOX_TYPE = 'BOX_TYPE';
const DEFAULT_TABS = [
    {
        value: 0,
        label: 'applications:carriage',
        type: CARRIAGE_TYPE,
        index: 0
    }
];
const getTabs = (tabs, t) => {
    return tabs.map((tab) => {
        return {
            ...tab,
            label: `${t(tab.label)}${tab.subTitle ? ` ${tab.subTitle}` : ''}`
        };
    });
};

const select = (response) => {
    let result = {
        tabs: [...DEFAULT_TABS],
        wagon: {}
    };
    if (response[0]?.containers) {
        result.tabs = [
            ...DEFAULT_TABS,
            ...response[0]?.containers?.map((container, i) => {
                return {
                    label: 'applications:box',
                    subTitle: container.containerCode,
                    type: BOX_TYPE,
                    value: +i + 1
                };
            })
        ];
        result.wagon = response[0];
    }
    return result;
};
