import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';
import { formatDate } from 'src/utils/dateUtils';

import { TableRowEditControls } from 'components/TableRowEditControls/TableRowEditControls';
import { TextLink } from 'components/TextLink/TextLink';

import { AWAITING_ACCEPTANCE } from 'modules/Applications/consts';
import {
    deleteApplication,
    updateApplication
} from 'modules/Applications/services/services';

import { ApplicationStatus } from '../ApplicationStatus/ApplicationStatus';
import { ApplicationsEditModal } from '../ApplicationsEditModal/ApplicationsEditModal';
import styles from './ApplicationTable.module.scss';

export const ApplicationTable = ({
    data = {},
    isFetching,
    sort,
    setSort,
    refetch
}) => {
    const { t } = useTranslation();

    const handleEdit = (id) => {
        showModal(ApplicationsEditModal, {
            id: id,
            onCreate: updateApplication
        }).then(refetch, noop);
    };

    const deleteMutation = useMutation({
        mutationFn: deleteApplication,
        onSuccess: refetch
    });

    const handleDelete = (id) => {
        showModal('confirm-delete').then(() => {
            deleteMutation.mutate(id);
        }, noop);
    };

    return (
        <div className={styles.wrapper}>
            <Table
                loading={isFetching}
                data={data || []}
                columns={getColumnsConfig(t, handleEdit, handleDelete)}
                sorting={sort}
                onSortingChange={setSort}
            />
        </div>
    );
};

const getColumnsConfig = (t, onEdit, onDelete) => {
    return [
        {
            header: '№',
            accessorKey: 'number',
            cell: ({ row }) => (
                <div className={styles.link}>
                    <TextLink
                        label={row.original.id}
                        url={`reception/${row.original.id}`}
                    />
                </div>
            ),
            minSize: 60,
            size: 60
        },
        {
            header: t('applications:application_date'),
            accessorKey: 'dt',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {formatDate(row.original.dt)}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:acceptance_start'),
            accessorKey: 'acceptedAt',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {formatDate(row.original.acceptedAt)}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:acceptance_end'),
            accessorKey: 'finishedAt',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {formatDate(row.original.finishedAt)}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('station'),
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {row.original.acceptanceStation?.name}
                </span>
            )
        },
        {
            header: t('cargo'),
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {row.original.cargoType?.nameRu}
                </span>
            )
        },
        {
            header: t('applications:applicant'),
            accessorKey: 'applicant',
            cell: ({ row }) => (
                <span className={styles.cell}>{row.original.applicant}</span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:security'),
            accessorKey: 'guardFullname',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {row.original.guardFullname}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:amount'),
            accessorKey: 'wagonCount',
            cell: ({ row }) => (
                <span className={styles.cell}>{row.original.wagonCount}</span>
            ),
            enableSorting: true
        },
        {
            header: t('status'),
            accessorKey: 'status',
            cell: ({ row }) => (
                <ApplicationStatus status={row.original.status} />
            ),
            enableSorting: true
        },
        {
            header: '',
            id: 'actions',
            cell: ({ row }) => {
                if (row.original.status === AWAITING_ACCEPTANCE) {
                    return (
                        <TableRowEditControls
                            onEdit={() => onEdit(row.original.id)}
                            onDelete={() => onDelete(row.original.id)}
                        />
                    );
                }
                return null;
            },
            size: 75,
            minSize: 75
        }
    ];
};
