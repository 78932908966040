import clsx from 'clsx';

import { CarriageContent } from '../components/CarriageContent/CarriageContent';
import { CarriagePageHeader } from '../components/CarriagePageHeader/CarriagePageHeader';
import styles from './CarriagePage.module.scss';

export const CarriagePage = () => {
    return (
        <div className={clsx(styles.carriage, 'flex-column', 'gap-24')}>
            <CarriagePageHeader />
            <CarriageContent />
        </div>
    );
};
