import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './WagonAcceptanceInSituationDetails.module.scss';

export const WagonAcceptanceInSituationDetails = ({ wagon }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.details}>
            <div className={styles.status}>
                <div
                    className={clsx(
                        styles.label,
                        !wagon.wagonAcceptanceInSituation && styles.notAccepted
                    )}
                >
                    {wagon.wagonAcceptanceInSituation
                        ? t('situations:wagonAcceptance.accepted')
                        : t('situations:wagonAcceptance.notAccepted')}
                    :
                </div>
                <div className={styles.details}>
                    {wagon.wagonAcceptanceInSituationDefect?.nameRu}
                </div>
            </div>
            {wagon.wagonAcceptanceInSituationText && (
                <>
                    <div className={styles.noteLabel}>
                        {t('situations:note')}
                    </div>
                    <div className={styles.note}>
                        {wagon.wagonAcceptanceInSituationText}
                    </div>
                </>
            )}
        </div>
    );
};
