import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';
import { formatDate } from 'src/utils/dateUtils';

import { TextLink } from 'components/TextLink/TextLink';

import { HistoryStatus } from 'modules/History/components/HistoryStatus';
import { HISTORY_PAGE_SIZE } from 'modules/History/consts';

import styles from './HistoryTable.module.scss';

export const HistoryTable = ({ sort, setSort, data, isLoading }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.table}>
            <Table
                loading={isLoading}
                data={data || []}
                columns={getColumnsConfig(t)}
                pageSize={HISTORY_PAGE_SIZE}
                sorting={sort}
                onSortingChange={setSort}
            />
        </div>
    );
};

const getColumnsConfig = (t) => {
    return [
        {
            header: '№',
            accessorKey: 'appId',
            cell: ({ row }) => (
                <div className={styles.link}>
                    <TextLink
                        label={row.original.appId}
                        url={`/history/${row.original.appId}/carriages/${row.original.appWagonId}`}
                    />
                </div>
            ),
            enableSorting: true
        },
        {
            header: t('carriage'),
            accessorKey: 'wagonCode',
            enableSorting: true
        },
        {
            header: t('history:container'),
            accessorKey: 'containerCode',
            enableSorting: true
        },
        {
            header: t('history:application_date'),
            accessorKey: 'dt',
            cell: ({ row }) => formatDate(row.original.dt),
            enableSorting: true,
            size: 200
        },
        {
            header: t('from'),
            cell: ({ row }) => row.original.acceptanceStation?.name
        },
        {
            header: t('to'),
            cell: ({ row }) => row.original.stanNazn?.name
        },
        {
            header: t('history:detached_date'),
            accessorKey: 'detachedAt',
            cell: ({ row }) => formatDate(row.original.detachedAt),
            enableSorting: true,
            size: 200
        },
        {
            header: t('cargo'),
            cell: ({ row }) => row.original.cargoType?.nameRu
        },
        {
            header: t('history:defect'),
            cell: ({ row }) => row.original.defect?.nameRu,
            size: 150
        },
        {
            header: t('history:remark'),
            cell: ({ row }) => row.original.remark,
            size: 200
        },
        {
            header: t('status'),
            accessorKey: 'initialStatus',
            cell: ({ row }) => (
                <HistoryStatus status={row.original.initialStatus} />
            ),
            enableSorting: true,
            size: 220
        }
    ];
};
