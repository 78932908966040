import { isNil, omitBy } from 'lodash';

export const CustomFilterParam = {
    encode(value) {
        if (!value) {
            return undefined;
        }
        return encodeURI(JSON.stringify(omitBy(value, isNil)));
    },
    decode(strValue) {
        if (!strValue) {
            return undefined;
        }
        return JSON.parse(decodeURI(strValue));
    }
};
