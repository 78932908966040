import clsx from 'clsx';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useScreenSize } from 'hooks/useScreenSize';

import { Card } from 'components/Card/Card';
import { TextButton } from 'components/TextButton/TextButton';

import {
    DEFAULT_SEAL,
    DEFAULT_TWIST,
    LEFT_DIRECTION,
    RIGHT_DIRECTION
} from 'modules/Applications/consts';

import { SealAttributesItem } from '../SealAttributesItem/SealAttributesItem';
import { SealAttributesItemView } from '../SealAttributesItemView/SealAttributesItemView';
import { TwistAttributesItem } from '../TwistAttributesItem/TwistAttributesItem';
import { TwistAttributesItemView } from '../TwistAttributesItemView/TwistAttributesItemView';
import styles from './ContainerAttributes.module.scss';

export const ContainerAttributes = ({
    containerIndex,
    methods,
    isDisabled
}) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    const sealFields = useFieldArray({
        control: methods.control,
        name: `containers.${containerIndex}.containersSeals`,
        keyName: 'containerSealsAttributesFieldId'
    });

    const twistFields = useFieldArray({
        control: methods.control,
        name: `containers.${containerIndex}.containersTwists`,
        keyName: 'containerTwistAttributesFieldId'
    });

    const handleAddSeal = (side) => {
        sealFields.append({
            ...DEFAULT_SEAL,
            sealSide: side
        });
    };

    const handleAddTwist = (side) => {
        twistFields.append({
            ...DEFAULT_TWIST,
            twistSide: side
        });
    };

    const handleDeleteSeal = (index) => {
        sealFields.remove(index);
    };

    const handleDeleteTwist = (index) => {
        twistFields.remove(index);
    };

    return (
        <div className={styles.attributes}>
            <Card
                title={t('applications:left_side')}
                hideActions={true}
                size="md"
                headerDirection={isMobile ? 'vertical' : 'horizontal'}
            >
                {sealFields.fields.map((seal, i) => {
                    if (seal.sealSide === LEFT_DIRECTION) {
                        return isDisabled ? (
                            <SealAttributesItemView
                                data={seal}
                                key={seal.containerSealsAttributesFieldId}
                            />
                        ) : (
                            <SealAttributesItem
                                data={seal}
                                onDelete={handleDeleteSeal}
                                attributeIndex={i}
                                fieldPath={`containers.${containerIndex}.containersSeals.${i}`}
                                key={seal.containerSealsAttributesFieldId}
                            />
                        );
                    }
                    return null;
                })}
                {twistFields.fields.map((twist, i) => {
                    if (twist.twistSide === LEFT_DIRECTION) {
                        return isDisabled ? (
                            <TwistAttributesItemView
                                data={twist}
                                key={twist.containerTwistAttributesFieldId}
                            />
                        ) : (
                            <TwistAttributesItem
                                data={twist}
                                onDelete={handleDeleteTwist}
                                attributeIndex={i}
                                fieldPath={`containers.${containerIndex}.containersTwists.${i}`}
                                key={twist.containerTwistAttributesFieldId}
                            />
                        );
                    }
                    return null;
                })}
                {!isDisabled && (
                    <div
                        className={clsx(
                            styles.actions,
                            isMobile && styles.fullWidth
                        )}
                    >
                        <TextButton
                            label={`+ ${t('applications:add_seal')}`}
                            onClick={() => handleAddSeal(LEFT_DIRECTION)}
                            noUnderline={true}
                        />
                        <TextButton
                            label={`+ ${t('applications:add_twist')}`}
                            onClick={() => handleAddTwist(LEFT_DIRECTION)}
                            noUnderline={true}
                        />
                    </div>
                )}
            </Card>
            <Card
                title={t('applications:right_side')}
                hideActions={true}
                size="md"
                headerDirection={isMobile ? 'vertical' : 'horizontal'}
            >
                {sealFields.fields.map((seal, i) => {
                    if (seal.sealSide === RIGHT_DIRECTION) {
                        return isDisabled ? (
                            <SealAttributesItemView
                                data={seal}
                                key={seal.containerSealsAttributesFieldId}
                            />
                        ) : (
                            <SealAttributesItem
                                data={seal}
                                onDelete={handleDeleteSeal}
                                attributeIndex={i}
                                fieldPath={`containers.${containerIndex}.containersSeals.${i}`}
                                key={seal.containerSealsAttributesFieldId}
                            />
                        );
                    }
                    return null;
                })}
                {twistFields.fields.map((twist, i) => {
                    if (twist.twistSide === RIGHT_DIRECTION) {
                        return isDisabled ? (
                            <TwistAttributesItemView
                                data={twist}
                                key={twist.containerTwistAttributesFieldId}
                            />
                        ) : (
                            <TwistAttributesItem
                                data={twist}
                                onDelete={handleDeleteTwist}
                                attributeIndex={i}
                                fieldPath={`containers.${containerIndex}.containersTwists.${i}`}
                                key={twist.containerTwistAttributesFieldId}
                            />
                        );
                    }
                    return null;
                })}
                {!isDisabled && (
                    <div
                        className={clsx(
                            styles.actions,
                            isMobile && styles.fullWidth
                        )}
                    >
                        <TextButton
                            label={`+ ${t('applications:add_seal')}`}
                            onClick={() => handleAddSeal(RIGHT_DIRECTION)}
                            noUnderline={true}
                        />
                        <TextButton
                            label={`+ ${t('applications:add_twist')}`}
                            onClick={() => handleAddTwist(RIGHT_DIRECTION)}
                            noUnderline={true}
                        />
                    </div>
                )}
            </Card>
        </div>
    );
};

export const ContainerAttributesView = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    const sealFields = data.containersSeals;
    const twistFields = data.containersTwists;

    return (
        <div className={styles.attributes}>
            <Card
                title={t('applications:left_side')}
                hideActions={true}
                size="md"
                headerDirection={isMobile ? 'vertical' : 'horizontal'}
            >
                {sealFields.map((seal, i) => {
                    if (seal.sealSide === LEFT_DIRECTION) {
                        return <SealAttributesItemView data={seal} key={i} />;
                    }
                    return null;
                })}
                {twistFields.map((twist, i) => {
                    if (twist.twistSide === LEFT_DIRECTION) {
                        return <TwistAttributesItemView data={twist} key={i} />;
                    }
                    return null;
                })}
            </Card>
            <Card
                title={t('applications:right_side')}
                hideActions={true}
                size="md"
                headerDirection={isMobile ? 'vertical' : 'horizontal'}
            >
                {sealFields.map((seal, i) => {
                    if (seal.sealSide === RIGHT_DIRECTION) {
                        return <SealAttributesItemView data={seal} key={i} />;
                    }
                    return null;
                })}
                {twistFields.map((twist, i) => {
                    if (twist.twistSide === RIGHT_DIRECTION) {
                        return <TwistAttributesItemView data={twist} key={i} />;
                    }
                    return null;
                })}
            </Card>
        </div>
    );
};
