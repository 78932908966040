export const RequiredField = {
    required: { value: true, message: 'form.validation.requiredField' }
};

export const SamePassword = {
    validate: (value, formData) => {
        if (!value && !formData.password) {
            return true;
        }
        return (
            value === formData.password || 'form.validation.passwordNotMatch'
        );
    }
};

export const SameNewPassword = {
    validate: (value, formData) => {
        if (!value && !formData.newPassword) {
            return true;
        }
        return (
            value === formData.newPassword || 'form.validation.passwordNotMatch'
        );
    },
    required: { value: true, message: 'form.validation.requiredField' }
};

export const EmailField = {
    validate: (value) => {
        if (!value) {
            return 'form.validation.requiredField';
        }
        if (value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
            return true;
        }
        return 'form.validation.incorrectEmail';
    }
};
