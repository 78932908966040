import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import api from 'core/api';
import { DataRequestPageListNormalizer } from 'core/normalizers/DataRequest';

export const useUserStation = () => {
    const userStationCode = useSelector(
        (state) => state.auth.user?.profileDataMap?.STATION_CODE
    );
    const select = useCallback(
        (data) => {
            return {
                ...data,
                code: userStationCode
            };
        },
        [userStationCode]
    );

    return useQuery({
        queryFn: () => getUserStation(userStationCode),
        queryKey: ['useUserStation'],
        initialData: {
            code: userStationCode
        },
        select
    });
};

const getUserStation = (userStationCode) => {
    return api.get('/qorgau-data/api/data-request/stations', {
        params: {
            code_eq: userStationCode
        },
        normalizer: (r) => {
            const result = DataRequestPageListNormalizer(r);
            if (result.content.length > 0) {
                return result.content[0];
            }
            return {};
        }
    });
};
