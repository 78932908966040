import { createSlice } from '@reduxjs/toolkit';
import { MIDDLE_BREAKPOINT } from 'settings';

const screenSizeSlice = createSlice({
    name: 'screenSize',
    initialState: {
        size:
            document.body.clientWidth > MIDDLE_BREAKPOINT
                ? 'DESKTOP'
                : 'MOBILE',
        isMobile: !(document.body.clientWidth > MIDDLE_BREAKPOINT)
    },
    reducers: {
        setScreenSize(state, action) {
            state.size = action.payload;
            if (action.payload === 'MOBILE') {
                state.isMobile = true;
            } else if (action.payload === 'DESKTOP') {
                state.isMobile = false;
            }
        }
    }
});

export const screenSizeReducer = screenSizeSlice.reducer;

export const screenSizeActions = screenSizeSlice.actions;
