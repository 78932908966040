import { HistoryCarriageSituations } from './pages/HistoryCarriageSituations';
import { HistoryPage } from './pages/HistoryPage';
import { HistorySituationDetailsPage } from './pages/HistorySituationDetailsPage/HistorySituationDetailsPage';

export const routes = [
    {
        path: '/history',
        element: <HistoryPage />
    },
    {
        path: '/history/:id/carriages/:appWagonId',
        element: <HistoryCarriageSituations />
    },
    {
        path: '/history/situations/:situationId/carriages/:appWagonId',
        element: <HistorySituationDetailsPage />
    }
];
